import { ConfirmState } from 'types/modals';
import { useConfirmContext } from './useCofirmContext';

export interface ConfirmProps extends Omit<ConfirmState, 'visible'> {
  text: string;
  textTitle?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

let resolveCallback = (_value: boolean) => {};

export const useConfirm = () => {
  const { confirmState, setConfirmState } = useConfirmContext();
  const hideConfirm = () => setConfirmState({ text: '', visible: false });

  const onConfirm = () => {
    hideConfirm();
    resolveCallback(true);
  };

  const onCancel = () => {
    hideConfirm();
  };
  const confirm = (props: ConfirmProps) => {
    setConfirmState({ ...props, visible: true });
    return new Promise((res) => {
      resolveCallback = res;
    });
  };

  return { confirm, onConfirm, onCancel, confirmState };
};
