import React, { useRef, useEffect } from 'react';
import type { MutableRefObject } from 'react';

export type UseOverflowResult = [boolean, MutableRefObject<null>];

export const useOverflow = (): UseOverflowResult => {
  const isOverflowRef = useRef(null);
  const [size, setSize] = React.useState(window.innerWidth);
  const [isOverflow, setIsOverflow] = React.useState(false);
  const resizeHandler = () => {
    setSize(window.innerWidth);
  };
  const overflowHandler = (element: HTMLElement | null) => {
    if (element) {
      setIsOverflow(element.offsetWidth < element.scrollWidth);
    }
  };
  React.useEffect(() => {
    window.onresize = resizeHandler;
    return () => {
      window.onresize = null;
    };
  }, []);
  useEffect(() => {
    overflowHandler(isOverflowRef.current);
  }, [size]);
  return [isOverflow, isOverflowRef];
};
