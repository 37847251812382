import { createSelector } from 'reselect';
import { RootState } from 'redux/store';
import { RequestKey, RequestStatus } from 'redux/ui/types';

const ui = (state: RootState) => state.ui;

export const getRequestsStatuses = (state: RootState): Record<RequestKey, RequestStatus> => ui(state).requests;
export const isPending = (state: RootState) => (key: RequestKey) => getRequestsStatuses(state)[key] === 'pending';
export const isFinished = (state: RootState) => (key: RequestKey) => getRequestsStatuses(state)[key] === 'success';
export const isFailed = (state: RootState) => (key: RequestKey) => getRequestsStatuses(state)[key] === 'error';
export const isCanceled = (key: RequestKey) =>
  createSelector(getRequestsStatuses, (requestsStatuses) => requestsStatuses[key] === 'notPerformed');
