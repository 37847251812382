export const downloadFile = (url: string) => {
  fetch(url).then((response) => {
    response.blob().then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.setAttribute('download', '');
      anchor.click();
      window.URL.revokeObjectURL(anchor.href);
    });
  });
};

export const exportFile = (url: string) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = 'fileName';
  anchor.click();
};
