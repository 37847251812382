import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import loadable from '@loadable/component';
import { useQueryParam } from 'use-query-params';
import { ArrayParam } from 'serialize-query-params/lib/params';
import { getEditingUser } from 'redux/users';
import { RouteInterface } from 'types/route';
import { getSpecificRedirect, resetSpecificRedirect, setSpecificRedirect } from 'components/Authentication/helpers';
import { validateProjectIdQuery } from 'lib/helpers/documentClass';
import { useSelector } from 'react-redux';

const Login = loadable(() => import('components/Authentication/Login/Login'));
const SignUp = loadable(() => import('components/Authentication/SignUp/SignUp'));
const ResetPassword = loadable(() => import('components/Authentication/ResetPassword/ResetPassword'));
const UnauthorizedAndForbiddenPage = loadable(
  () => import('components/Common/UnauthorizedAndForbiddenPage/UnauthorizedAndForbiddenPageContainer')
);
const VerifyEmail = loadable(() => import('components/Authentication/VerifyEmail/VerifyEmail'));
const Team = loadable(() => import('components/Team/Team'));
const Projects = loadable(() => import('components/Projects/Projects'));
const ManageProjectUsers = loadable(() => import('components/Projects/ManageProjectUsers/ManageProjectUsers'));
const Documents = loadable(() => import('components/Documents/Documents'));
const DocumentContainer = loadable(() => import('components/Document/DocumentContainer'));
// const Dashboard = loadable(() => import('components/Dashboard/Dashboard'));
const Users = loadable(() => import('components/Users/Users'));
const AddUsersToProjectContainer = loadable(
  () => import('components/Users/AddUsersToProject/AddUsersToProjectContainer')
);
// const RolesContainer = loadable(() => import('components/Roles/RolesContainer'));
const PageNotFound = loadable(() => import('components/Common/PageNotFound/PageNotFoundContainer'));

const privatePaths = ['projects', 'documents', 'document', 'document/:id/review', 'team'];

const publicPaths = ['signup', 'login', 'reset_password', 'reset_password/verify', 'verify-code', 'signup_success'];

export interface ApplicationRoutesProps {
  isAuth: boolean;
  isAdmin: boolean;
}

const ApplicationRoutes: React.FC<ApplicationRoutesProps> = ({ isAuth, isAdmin }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const editingUser = useSelector(getEditingUser);

  if (!isAuth && location.pathname !== '/login') {
    setSpecificRedirect(location.pathname + location.search);
  }

  const [projectIdQuery] = useQueryParam('projectId', ArrayParam);

  const isUnAuthPath = React.useMemo(() => {
    return publicPaths.find((path) => location.pathname.includes(path));
  }, [location]);

  const isAuthPath = React.useMemo(() => {
    return privatePaths.find((path) => location.pathname.includes(path));
  }, [location]);

  const commonRoutes = React.useMemo(
    () => [
      ...['/unauthorized', '/forbidden'].map((path) => ({
        path,
        exact: true,
        component: (<UnauthorizedAndForbiddenPage />) as React.ReactNode,
      })),
      { path: '*', component: (<PageNotFound />) as React.ReactNode },
    ],
    []
  );

  const authUserRoutes: RouteInterface[] = React.useMemo(() => {
    const routes = [
      ...['/projects', '/'].map((path) => ({
        path,
        component: (<Projects />) as React.ReactNode,
      })),
      { path: '/documents', exact: true, component: <Documents /> },
      { path: '/document/:id/review', exact: true, component: <DocumentContainer /> },
      { path: '/team', exact: true, component: <Team /> },
    ];
    return [...routes, ...commonRoutes];
  }, [commonRoutes]);

  const authUserAdminRoutes: RouteInterface[] = React.useMemo(() => {
    const routes = [
      ...['/projects', '/'].map((path) => ({
        path,
        component: (<Projects />) as React.ReactNode,
      })),
      {
        path: '/projects/manage_users',
        exact: true,
        component: <ManageProjectUsers />,
      },
      { path: '/documents', exact: true, component: <Documents /> },
      { path: '/document/:id/review', exact: true, component: <DocumentContainer /> },
      { path: '/team', exact: true, component: <Team /> },
      // { path: '/dashboard', exact: true, component: <Dashboard /> },
      { path: '/users', exact: true, component: <Users /> },
      { path: '/users/add_to_project', exact: true, component: <AddUsersToProjectContainer /> },
      // { path: '/roles', exact: true, component: <RolesContainer /> },
    ];
    return [...routes, ...commonRoutes];
  }, [commonRoutes]);

  const nonAuthRoutes: RouteInterface[] = React.useMemo(
    () => [
      ...['/login', '/'].map((path) => ({
        path,
        component: (<Login />) as React.ReactNode,
      })),
      { path: '/signup', component: (<SignUp />) as React.ReactNode },
      { path: '/reset_password', component: (<ResetPassword />) as React.ReactNode },
      { path: '/reset_password/verify', component: (<ResetPassword />) as React.ReactNode },
      { path: '/verify-code', component: (<VerifyEmail />) as React.ReactNode },
      { path: '/signup_success', component: (<SignUp />) as React.ReactNode },
      ...commonRoutes,
    ],
    [commonRoutes]
  );

  const adminRoute = isAdmin ? authUserAdminRoutes : authUserRoutes;
  const routes = isAuth ? adminRoute : nonAuthRoutes;

  const getRedirects = React.useCallback(() => {
    const spRedirect = getSpecificRedirect();
    const locationPathname = location.pathname;
    if (isAuth && isUnAuthPath) {
      const filterRoutes = routes.filter((el) => el.path.includes(spRedirect as string));
      const currentRedirect = filterRoutes.length ? spRedirect : '/projects';

      navigate(`${currentRedirect}`);
      resetSpecificRedirect();
    }

    if (!isAuth && isAuthPath) {
      navigate('/login');
    }

    if (locationPathname.includes('/documents')) {
      if (!projectIdQuery) {
        navigate('/projects');
      } else if (!validateProjectIdQuery(projectIdQuery)) {
        navigate('/404');
      }
    }
    // TODO: Here must be a verification for the user's permission to get into the users page.
    if (locationPathname.includes('/users/add_to_project') && !editingUser) {
      navigate('/users');
    }
  }, [editingUser, isAuth, routes, isAuthPath, isUnAuthPath, location.pathname, navigate, projectIdQuery]);

  React.useEffect(() => {
    getRedirects();
  }, [getRedirects]);

  const generateRoutes = React.useMemo(
    () => routes.map(({ path, component }) => <Route element={component} path={path} key={path} />),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [routes, isAuth]
  );

  return <Routes>{generateRoutes}</Routes>;
};

export default ApplicationRoutes;
