import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { Project } from 'generatedSources';
import { groupBy } from 'lib/api/helpers';
import { isFinished } from 'redux/ui';
import { Model, ModelProvider, UseCase } from './types';

const projects = (state: RootState) => state.projects;

export const getProjects = (state: RootState): Array<Project> => projects(state).projects;
export const getUseCaseList = (state: RootState): Array<UseCase> => projects(state).useCaseList;
export const getUseCaseNames = (state: RootState): Array<string> =>
  getUseCaseList(state).map(({ useCaseName }) => useCaseName);
export const getUseCaseByName = (state: RootState, useCaseName: string): UseCase => {
  const useCaseList = getUseCaseList(state);
  return useCaseList.find((useCase) => useCase.useCaseName === useCaseName) as UseCase;
};
export const getUseCaseIdByName = (state: RootState, useCaseName: string): number => {
  const useCaseByName = getUseCaseByName(state, useCaseName);
  return useCaseByName.id;
};

export const getUseCaseModelsByName = (state: RootState, useCaseName: string): Array<Model> => {
  const useCaseByName = getUseCaseByName(state, useCaseName);
  return useCaseByName?.models || [];
};
export const getModels = (state: RootState): Array<Model> => projects(state).models;

export const getModelsProvidersAccordingToSelectedUseCase =
  (state: RootState) =>
  (selectedUseCase: string): Array<ModelProvider> => {
    const useCaseModels = getUseCaseModelsByName(state, selectedUseCase);
    return Array.from(new Set(useCaseModels.map(({ provider }) => provider)));
  };

export const getModelsGroupByModelProviders = (state: RootState): Record<string, Model[]> => {
  const models = getModels(state);
  return groupBy(models, 'provider');
};
export const getModelsBySpecificProvider =
  (state: RootState) =>
  (provider: ModelProvider): Array<Model> => {
    const modelsGroupByModelProviders = getModelsGroupByModelProviders(state);
    if (modelsGroupByModelProviders[provider]) return modelsGroupByModelProviders[provider];
    return [];
  };
export const getModelsBySelectedProviderAndUseCase =
  (state: RootState) =>
  (selectedUseCase: string, selectedProvider: ModelProvider): Array<Model> =>
    getUseCaseModelsByName(state, selectedUseCase)?.filter((model) => model.provider === selectedProvider);
export const getProjectModelInTheListOfAvailableModels = (state: RootState) => (modelUrl: string) => {
  const models = getModels(state);
  return models.find((model) => model.modelUrl === modelUrl);
};
export const getProjectModelIdByProjectID = (state: RootState) => (projectID: number) => {
  const projectsList = getProjects(state);
  const documentsProject = projectsList.find((project) => project.id === projectID);
  return documentsProject?.modelId;
};
export const getEditingProject = (state: RootState): Project | null => projects(state).editingProject;
export const getEditingProjectUseCaseID = (state: RootState): number | null =>
  getEditingProject(state)?.useCaseId || null;
export const getCurrentProject = (state: RootState): Project | null => projects(state).currentProject;
export const getCurrentProjectModelUpdatedTimestamp = (state: RootState): number | undefined =>
  getCurrentProject(state)?.modelUpdatedAt;
export const getClientName = (state: RootState): string | null => {
  const currentProject = getCurrentProject(state);
  if (currentProject) return currentProject.name;
  return null;
};
export const getCurrentProjectName = (state: RootState): string | null => {
  const currentProject = getCurrentProject(state);
  if (currentProject) return currentProject.displayName;
  return null;
};
export const getCurrentProjectId = (state: RootState): number | null => {
  const currentProject = getCurrentProject(state);
  if (currentProject) return currentProject.id;
  return null;
};
export const getEditingProjectModelUrl = (state: RootState): string | null => {
  const editingProject = getEditingProject(state);
  if (editingProject) return editingProject.modelUrl;
  return null;
};
export const getEditingProjectDisplayName = (state: RootState): string | null =>
  getEditingProject(state)?.displayName || null;
export const getProjectDisplayName = (state: RootState): string | null => getCurrentProject(state)?.displayName || null;
export const isAnyDocumentAlreadyInTheProject = (state: RootState): boolean => {
  const documentCounts = getCurrentProject(state)?.documentCounts;
  if (documentCounts) {
    const count = Object.values(documentCounts).reduce((acc, cur) => acc + cur, 0);
    return count > 0;
  }
  return false;
};
export const getNumberOfUnverifiedDocumentsInTheProject = (state: RootState): number => {
  const documentCounts = getCurrentProject(state)?.documentCounts;
  if (documentCounts?.UNVERIFIED) return documentCounts?.UNVERIFIED;
  return 0;
};

export const isNumberOfUnverifiedDocumentsMoreThanOne = (state: RootState): boolean =>
  getNumberOfUnverifiedDocumentsInTheProject(state) > 1;

export const getProjectName = (state: RootState): string | null => getCurrentProject(state)?.name || null;
export const selectProjectName = createSelector([getProjectName], (name) => name);
export const getCurrentProjectModelUrl = (state: RootState): string | null => {
  const project = getCurrentProject(state);
  if (project) return project.modelUrl;
  return null;
};
export const getCurrentProjectUseCaseID = (state: RootState): number | null =>
  getCurrentProject(state)?.useCaseId || null;
export const getCurrentProjectID = (state: RootState): number | null => {
  const currentProject = getCurrentProject(state);
  if (currentProject) return currentProject.id;
  return null;
};
export const getCurrentVerifyThreshold = (state: RootState): number => {
  const project = getCurrentProject(state);
  if (project) return project.verifyThreshold;
  return 1;
};

export const getCurrentProjectDocumentsNumber = (documentsCountByStatuses: Record<string, number>): number => {
  const totalNumberOfTheDocumentInTheCurrentProject = Object.values(documentsCountByStatuses).reduce(
    (acc, current) => acc + current,
    0
  );
  return totalNumberOfTheDocumentInTheCurrentProject;
};

export const getSelectedProject = createSelector(
  getEditingProject,
  getCurrentProject,
  (editingProject, currentProject) => editingProject || currentProject
);

const getIsCurrentProjectFetched = (state: RootState) => !!getCurrentProject(state);

export const selectIsCurrentProjectFetched = createSelector(
  [getIsCurrentProjectFetched, isFinished],
  (isCurrentProjectFetched, isFinishedFunc) => isCurrentProjectFetched && isFinishedFunc('FETCH_SINGLE_PROJECT')
);
