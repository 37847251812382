import { createActionType } from 'lib/helpers/redux';

export const projectsPrefix = 'projects';

export const fetchModelsAction = createActionType(projectsPrefix, 'fetchModels');

export const fetchProjectsAction = createActionType(projectsPrefix, 'fetchProjects');

export const fetchProjectAction = createActionType(projectsPrefix, 'fetchProject');

export const createProjectAction = createActionType(projectsPrefix, 'createProject');

export const archiveProjectAction = createActionType(projectsPrefix, 'archiveProject');

export const changeProjectModelAction = createActionType(projectsPrefix, 'changeProjectModel');

export const changeEditingProjectAction = createActionType(projectsPrefix, 'changeEditingProject');

export const changeCurrentProjectAction = createActionType(projectsPrefix, 'changeCurrentProject');

export const saveEditingProjectAction = createActionType(projectsPrefix, 'saveEditingProject');

export const fetchUseCaseListAction = createActionType(projectsPrefix, 'fetchUseCaseList');

export const fetchProjectUseCaseAction = createActionType(projectsPrefix, 'fetchProjectUseCase');
