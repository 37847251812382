import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';
import { DocumentListState } from './list/types';
import listReducer from './list';
import review from './review';

const persistConfig: PersistConfig<DocumentListState, AnyAction> = {
  key: 'list',
  version: 1,
  storage,
  whitelist: ['lastVisitedDocumentsListQueries'],
};

const list = persistReducer(persistConfig, listReducer);

export default combineReducers({ list, review });
