import { Dictionary } from '@reduxjs/toolkit';
import { groupBy, mapValues, sortBy } from 'lodash';
import { Cells, Forms, Items, ItemsInfo, TablesInterface } from 'generatedSources';
import { relativeToAbsoluteBBox } from 'lib/bbox';
import { MapOfEntitiesByPages, PageEntities, Pages } from 'redux/document/review/types';

export const removeCellFromRow = (row: Cells[], indexToRemove: number): Cells[] =>
  row
    .filter((cell) => cell.columnId !== indexToRemove)
    .map((c) => ({ ...c, columnId: c.columnId > indexToRemove ? c.columnId - 1 : c.columnId }));

export const getEmptyCell = (rowId: number, columnId: number, currentPage: number, tableIndex: number): Cells => ({
  rowId,
  columnId,
  pageId: currentPage,
  tableIndex,
  bbox: null,
  text: '',
});

export const getRecalculatedRowIds = (currentPageTableCells: Cells[], newRowIndex: number) => {
  return currentPageTableCells.map((cell) => {
    if (Number(cell.rowId) < newRowIndex) return cell;
    return { ...cell, rowId: cell.rowId + 1 };
  });
};

const pageEntities: PageEntities[] = ['Tables', 'Results'];

/* eslint-disable no-param-reassign */
const createMapForInfo = (
  info: Array<Items | TablesInterface>
): { [key: number]: Items } | { [key: number]: Dictionary<Cells[]> } => {
  return info.reduce((acc: { [key: number]: Items } | { [key: number]: Dictionary<Cells[]> }, item) => {
    if ('index' in item) {
      acc[item.index] = item;
    } else {
      const groupCellsByRowId = groupBy(item.cells, 'rowId');
      acc[item.tableIndex] = mapValues(groupCellsByRowId, (cells) => sortBy(cells, 'columnId'));
    }
    return acc;
  }, {});
};

export const setMapForPagesInfo = (data: Pages): MapOfEntitiesByPages => {
  return pageEntities.reduce(
    (accum, info) => ({
      ...accum,
      [info === 'Results' ? 'items' : 'tables']: Object.keys(data).reduce(
        (acc: Record<number, Record<number, Items | Dictionary<Cells[]>>>, pageNumber) => {
          const pageInfo = data[Number(pageNumber)][info === 'Results' ? 'items' : 'tables'];
          if (pageInfo !== undefined) {
            acc[Number(pageNumber)] = createMapForInfo(pageInfo);
          }
          return acc;
        },
        {}
      ),
    }),
    { items: {}, tables: {} }
  );
};

const getHighestCoordinateFromBBox = (bbox: number[]) => relativeToAbsoluteBBox(bbox, 1, 1).top;

const getItemInfoWithHighestCoordinate = (itemsInfo: ItemsInfo[]): ItemsInfo | null => {
  return itemsInfo.reduce((prevItemInfo, currentItemInfo) => {
    if (prevItemInfo.bbox && currentItemInfo.bbox) {
      const prevItemInfoBBoxTopCoordinate = getHighestCoordinateFromBBox(prevItemInfo.bbox);
      const currentItemInfoBBoxTopCoordinate = getHighestCoordinateFromBBox(currentItemInfo.bbox);
      if (currentItemInfoBBoxTopCoordinate > prevItemInfoBBoxTopCoordinate) return currentItemInfo;
    }
    return prevItemInfo;
  });
};

export const sortFormsByBBoxValues = (formsByPages: { page: string; forms: Forms[] | undefined }[]) => {
  return formsByPages.map(({ forms, page }) => {
    if (forms) {
      const newForms: Forms[] = [];
      forms.forEach((currentForm) => {
        if (!newForms.length) newForms.push(currentForm);
        else {
          const currentFormHighestCoordinate = getHighestCoordinateFromBBox(currentForm.valueBbox as number[]);
          const indexOfFormWhichLowerThanCurrentForm = newForms.findIndex((prevForm) => {
            const previousFormHighestCoordinate = getHighestCoordinateFromBBox(prevForm.valueBbox as number[]);
            return currentFormHighestCoordinate < previousFormHighestCoordinate;
          });
          if (indexOfFormWhichLowerThanCurrentForm !== -1) {
            newForms.splice(indexOfFormWhichLowerThanCurrentForm, 0, currentForm);
          } else {
            newForms.push(currentForm);
          }
        }
      });
      return {
        page,
        forms: newForms,
      };
    }
    return { page, forms };
  });
};

export const sortResultsByBBoxValues = (resultsByPages: { page: string; items: Items[] | undefined }[]) => {
  return resultsByPages.map(({ items, page }) => {
    if (items) {
      const newItems: Items[] = [];
      items.forEach((currentItem) => {
        if (!newItems.length || !currentItem.itemsInfo.length) newItems.push(currentItem);
        else {
          const highestCurrentItemInfoCoordinate = getItemInfoWithHighestCoordinate(currentItem.itemsInfo);
          const indexOfItemWhichLowerThanCurrentItem = newItems.findIndex((prevItems) => {
            const currentItemInfoWithHighestCoordinate = getHighestCoordinateFromBBox(
              highestCurrentItemInfoCoordinate?.bbox as number[]
            );

            const prevItemInfoWithHighestCoordinate = getHighestCoordinateFromBBox(
              getItemInfoWithHighestCoordinate(prevItems.itemsInfo)?.bbox as number[]
            );
            return currentItemInfoWithHighestCoordinate < prevItemInfoWithHighestCoordinate;
          });
          if (indexOfItemWhichLowerThanCurrentItem !== -1) {
            newItems.splice(indexOfItemWhichLowerThanCurrentItem, 0, currentItem);
          } else {
            newItems.push(currentItem);
          }
        }
      });
      return {
        page,
        items: newItems,
      };
    }
    return { page, items };
  });
};
