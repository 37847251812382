export type DocumentsActionTypes = 'delete' | 'download' | 'export' | 'export verified documents';

export enum DocumentsProcessingStep {
  'Upload',
  'Categorize',
  'Review',
  'Export',
}

export type ExportType = 'xls' | 'crm' | 'fhir';

export interface IDocumentActions {
  action: '' | DocumentsActionTypes;
  selectedIDs: number[];
  setSelectedIDs: (IDs: number[]) => void;

  showDownloadConfirmation: (ID?: number) => void;
  showExportConfirmation: (ID?: number) => void;
  showDeleteConfirmation: (ID?: number) => void;
  showExportVerifiedDocumentsConfirmation: () => void;
  cancelAction: () => void;

  exportDocuments: (exportType: ExportType) => void;
  deleteDocuments: () => void;
  downloadDocuments: () => void;
}
