import React from 'react';
import classnames from 'classnames/bind';
import { Modal as AntdModal } from 'antd';
import { ModalSize, ModalType } from 'types/common';
import { ReactComponent as CloseIcon } from 'assets/images/svg/close.svg';
import classes from './Modal.module.scss';

export interface ModalProps {
  title?: React.ReactNode;
  titleCaption?: React.ReactNode;
  secondaryText?: React.ReactNode;
  body?: React.ReactNode;
  actionsButtons?: React.ReactNode;
  closeIcon?: React.ReactNode;
  visible: boolean;
  closable?: boolean;
  maskClosable?: boolean;
  size?: ModalSize;
  modalType?: ModalType;
  modalClassName?: string;
  titleClassName?: string;
  titleCaptionClassName?: string;
  secondaryTextClassName?: string;
  bodyClassName?: string;
  actionsButtonsClassName?: string;
  modalDataTestID?: string;
  titleDataTestID?: string;
  titleCaptionDataTestID?: string;
  secondaryTextDataTestID?: string;
  bodyDataTestID?: string;
  actionsButtonsDataTestID?: string;
  onHide?(): void;
}

const cx = classnames.bind(classes);

const MASK_STYLE = { background: '#00000073' };

const Modal: React.FC<ModalProps> = ({
  title,
  titleCaption,
  secondaryText,
  body,
  actionsButtons,
  closeIcon,
  modalClassName,
  titleClassName,
  titleCaptionClassName,
  secondaryTextClassName,
  bodyClassName,
  actionsButtonsClassName,
  closable,
  modalType = 'regular',
  maskClosable,
  modalDataTestID,
  titleDataTestID,
  titleCaptionDataTestID,
  secondaryTextDataTestID,
  bodyDataTestID,
  actionsButtonsDataTestID,
  visible,
  size = 'small',
  onHide,
}) => (
  <AntdModal
    data-testid={modalDataTestID}
    maskTransitionName="none"
    closeIcon={closeIcon || <CloseIcon data-testid={`${modalDataTestID}-close-icon`} />}
    open={visible}
    centered
    maskStyle={MASK_STYLE}
    maskClosable={maskClosable}
    closable={closable}
    className={cx(modalClassName, size, modalType, 'Modal')}
    destroyOnClose
    onCancel={onHide}
    footer={false}
  >
    {title && (
      <div className={cx('Title', titleClassName)} data-testid={titleDataTestID}>
        {title}
      </div>
    )}
    {titleCaption && (
      <div className={cx('TitleCaption', titleCaptionClassName)} data-testid={titleCaptionDataTestID}>
        {titleCaption}
      </div>
    )}
    {secondaryText && (
      <div className={cx('SecondaryText', secondaryTextClassName)} data-testid={secondaryTextDataTestID}>
        {secondaryText}
      </div>
    )}
    {body && (
      <div className={cx('Body', bodyClassName, modalType)} data-testid={bodyDataTestID}>
        {body}
      </div>
    )}
    {actionsButtons && (
      <div className={cx('ActionsButtons', actionsButtonsClassName)} data-testid={actionsButtonsDataTestID}>
        {actionsButtons}
      </div>
    )}
  </AntdModal>
);

export default Modal;
