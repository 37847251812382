import { getRequestsSetters, isPending } from 'redux/ui';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClientId } from 'redux/login';
import { Project, ProjectStatus } from 'generatedSources';
import * as api from 'lib/api/projects';
import { RootState } from 'redux/store';
import { getTotal, setTotal } from 'redux/document/list';
import { showErrorMessage, showSuccessMessage } from 'components/Common/Alert/Alerts';

import {
  getCurrentProject,
  getEditingProject,
  getModels,
  getProjectModelInTheListOfAvailableModels,
  getProjects,
  getUseCaseIdByName,
} from './selectors';
import * as actions from './actions';
import { Model, SingleUseCaseResponse } from './types';

export const fetchModels = createAsyncThunk(actions.fetchModelsAction, async (_: undefined, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const clientId = getClientId(state);
  const { setFailed, setPending, setSuccess } = getRequestsSetters('FETCH_MODELS');
  await thunkAPI.dispatch(setPending());
  try {
    if (clientId) {
      const response: Model[] = await api.fetchModels();
      await thunkAPI.dispatch(setSuccess());
      return response;
    }
    return [];
  } catch (e) {
    thunkAPI.dispatch(setFailed());
    const error = e as Error;
    throw Error(error.message);
  }
});

export const changeCurrentProject = createAsyncThunk(
  actions.changeCurrentProjectAction,
  async (project: Project | null) => {
    try {
      return project;
    } catch (e) {
      const error = e as Error;
      throw Error(error.message);
    }
  }
);

export const startFetchSingleProject = createAsyncThunk(
  actions.changeCurrentProjectAction,
  async (project: Project | null, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    if (getTotal(state)) thunkAPI.dispatch(setTotal(0));
    const { setPending } = getRequestsSetters('FETCH_SINGLE_PROJECT');
    thunkAPI.dispatch(setPending());
  }
);

export const fetchUseCaseList = createAsyncThunk(actions.fetchUseCaseListAction, async (_, thunkAPI) => {
  const { setFailed, setPending, setSuccess } = getRequestsSetters('FETCH_USE_CASE_LIST');
  thunkAPI.dispatch(setPending());
  try {
    const useCaseList = await api.getUseCases();
    thunkAPI.dispatch(setSuccess());
    return useCaseList.map((useCase) => ({
      ...useCase,
      useCaseDefaultSettings: JSON.parse(useCase.useCaseDefaultSettings),
    }));
  } catch (e) {
    thunkAPI.dispatch(setFailed());
    const error = e as Error;
    throw Error(error.message);
  }
});

export const fetchProjects = createAsyncThunk(actions.fetchProjectsAction, async (clientId: number, thunkAPI) => {
  const { setFailed, setPending, setSuccess } = getRequestsSetters('FETCH_PROJECTS');
  await thunkAPI.dispatch(setPending());
  try {
    if (clientId) {
      const response: Project[] = await api.fetchProjects(clientId);
      const models = getModels(thunkAPI.getState() as RootState);
      if (models.length === 0) thunkAPI.dispatch(fetchModels());
      thunkAPI.dispatch(fetchUseCaseList());
      thunkAPI.dispatch(setSuccess());
      return response;
    }
    return [];
  } catch (e) {
    thunkAPI.dispatch(setFailed());
    const error = e as Error;
    throw Error(error.message);
  }
});

export const fetchProjectUseCase = createAsyncThunk<
  SingleUseCaseResponse,
  { projectID: number },
  { rejectValue: null }
>(actions.fetchProjectUseCaseAction, async (payload, thunkAPI) => {
  const { setFailed, setPending, setSuccess } = getRequestsSetters('FETCH_PROJECT_USE_CASE');
  await thunkAPI.dispatch(setPending());
  try {
    let response: SingleUseCaseResponse = await api.getProjectUseCase(payload.projectID);
    response = { ...response };
    thunkAPI.dispatch(setSuccess());
    return response;
  } catch (e) {
    thunkAPI.dispatch(setFailed());
    return thunkAPI.rejectWithValue(null);
  }
});

export const fetchProject = createAsyncThunk(
  actions.fetchProjectAction,
  // eslint-disable-next-line consistent-return
  async (projectId: number, thunkAPI) => {
    const { setFailed, setPending, setSuccess } = getRequestsSetters('FETCH_SINGLE_PROJECT');
    const state = thunkAPI.getState() as RootState;
    const projects = getProjects(state);
    const currentProject = projects.find((project) => project.id === projectId);
    if (currentProject) {
      thunkAPI.dispatch(changeCurrentProject(currentProject));
      thunkAPI.dispatch(setSuccess());
      return null;
    }
    if (!isPending(state)('FETCH_SINGLE_PROJECT')) await thunkAPI.dispatch(setPending());
    const clientId = getClientId(state);
    try {
      if (clientId) {
        const response: Project = await api.fetchSingleProject(clientId, projectId);
        await thunkAPI.dispatch(fetchModels());
        await thunkAPI.dispatch(changeCurrentProject(response));
        await thunkAPI.dispatch(setSuccess());
        return response;
      }
      return null;
    } catch (e) {
      thunkAPI.dispatch(setFailed());
      const error = e as Error;
      throw Error(error.message);
    }
  }
);

interface CreateProjectProps {
  name: string;
  useCase: string;
  modelUrl: string;
  signal?: AbortSignal;
}

export const createProject = createAsyncThunk(
  actions.createProjectAction,
  // eslint-disable-next-line consistent-return
  async (args: CreateProjectProps, thunkAPI) => {
    const { name, useCase, modelUrl, signal } = args;
    const state = thunkAPI.getState() as RootState;
    const clientId = getClientId(state);
    const useCaseId = getUseCaseIdByName(state, useCase);
    const model = getProjectModelInTheListOfAvailableModels(state)(modelUrl);
    const project = {
      id: null,
      name,
      displayName: name,
      clientId: clientId !== undefined ? clientId : null,
      modelUrl,
      useCaseId,
      modelId: model?.id,
      status: ProjectStatus.ACTIVE,
      documentCounts: {},
    };
    const { setFailed, setPending, setSuccess } = getRequestsSetters('CREATE_PROJECT');
    await thunkAPI.dispatch(setPending());
    try {
      if (clientId) {
        const response: void = await api.createProject(clientId, project, signal);
        thunkAPI.dispatch(setSuccess());

        showSuccessMessage(`Project ${name} was successfully created!`);

        thunkAPI.dispatch(fetchProjects(clientId));
        return response;
      }
      return null;
    } catch (e) {
      thunkAPI.dispatch(setFailed());
      let message = `Something went wrong...`;
      if (e instanceof Error) message = e.message;
      showErrorMessage(message);
    }
  }
);

export const archiveProjectToolkit = createAsyncThunk(actions.archiveProjectAction, async (id: number, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const clientId = getClientId(state);
  const { setFailed, setPending, setSuccess } = getRequestsSetters('ARCHIVE_PROJECT');
  await thunkAPI.dispatch(setPending());
  try {
    if (clientId) {
      const response: Project = await api.archiveProject(clientId, id);
      thunkAPI.dispatch(setSuccess());
      return response.id;
    }
    return null;
  } catch (e) {
    thunkAPI.dispatch(setFailed());
    const error = e as Error;
    throw Error(error.message);
  }
});

export const changeProjectModel = createAsyncThunk(
  actions.changeProjectModelAction,
  async (modelUrl: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const editingProject = getEditingProject(state);
    try {
      if (editingProject) return { ...editingProject, modelUrl };
      return null;
    } catch (e) {
      const error = e as Error;
      throw Error(error.message);
    }
  }
);

export const changeEditingProject = createAsyncThunk(
  actions.changeEditingProjectAction,
  async (project: Project | null, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const editingProject = getCurrentProject(state);
    try {
      if (editingProject) return project;
      return null;
    } catch (e) {
      const error = e as Error;
      throw Error(error.message);
    }
  }
);

export const saveEditingProject = createAsyncThunk(
  actions.saveEditingProjectAction,
  async (projectName: string | null, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const clientId = getClientId(state);
    const projects = getProjects(state);
    let editingProject = getEditingProject(state);
    if (projectName && editingProject) {
      editingProject = { ...editingProject, displayName: projectName };
    }

    const { setFailed, setPending, setSuccess } = getRequestsSetters('UPDATE_PROJECTS');
    await thunkAPI.dispatch(setPending());
    try {
      if (clientId && editingProject && editingProject.id) {
        const currentProject = await api.updateProject(clientId, editingProject.id, editingProject);
        const newProjects = projects.map((project) => {
          if (project.id === editingProject?.id) return editingProject;
          return project;
        });
        thunkAPI.dispatch(setSuccess());
        return { currentProject, newProjects };
      }
      return null;
    } catch (e) {
      thunkAPI.dispatch(setFailed());
      const error = e as Error;
      throw Error(error.message);
    }
  }
);
