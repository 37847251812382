import React from 'react';
import ReactDOM from 'react-dom';
// TODO: change this to stable router after Prompt is returned in 6x version of react-router
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Amplify } from 'aws-amplify';
import './index.scss';
import './hacks/pdfjs';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { createBrowserHistory } from 'history';
import AppContainer from './components/App/AppContainer';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './redux/store';
import awsconfig from './aws';

export const history = createBrowserHistory();

Amplify.configure(awsconfig);

const basePath = process.env.REACT_APP_ROUTING_BASE_URL;
if (basePath) {
  document.querySelector('base')?.setAttribute('href', basePath);
}

ReactDOM.render(
  <React.StrictMode>
    <Router basename={basePath} history={history}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppContainer />
          </PersistGate>
        </Provider>
      </QueryParamProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root') || document.createElement('div')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
