/// <reference path="./custom.d.ts" />
// tslint:disable

import { DocumentWithAssignedUser, Pages as LocalPage } from 'redux/document/review/types';
/**
 * Document intelligence
 * This is an API for the document-intelligence module
 *
 * OpenAPI spec version: 1.1.0
 * Contact: you@your-company.com
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from 'url';
import isomorphicFetch from 'isomorphic-fetch';
import { Configuration } from './configuration';

const BASE_PATH = 'https://virtserver.swaggerhub.com/alexeyzavyalov/DocumentAI/1.1.0'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  // @ts-ignore
  protected configuration: Configuration;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected fetch: FetchAPI = isomorphicFetch
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  // @ts-ignore
  name: 'RequiredError';

  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

/**
 *
 * @export
 * @interface Client
 */
export interface Client {
  /**
   *
   * @type {number}
   * @memberof Client
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof Client
   */
  active?: boolean;
}
/**
 *
 * @export
 * @interface Document
 */
export interface Document {
  /**
   *
   * @type {number}
   * @memberof Document
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Document
   */
  tags: string[];
  /**
   *
   * @type {Date}
   * @memberof Document
   */
  uploadDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  path: string;
  /**
   *
   * @type {Project}
   * @memberof Document
   */
  projectId: number;
  /**
   *
   * @type {User}
   * @memberof Document
   */
  uploadedBy?: User;
  assignedAt?: string;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  rate?: number;
  /**
   *
   * @type {DocumentStatus}
   * @memberof Document
   */
  status: DocumentStatus;
  /**
   *
   * @type {DocumentType}
   * @memberof Document
   */
  documentClass: DocumentType;
  /**
   *
   * @type {LocalPage}
   * @memberof Document
   */
  pages: LocalPage;
  corrected?: boolean;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  confidence: number | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum DocumentStatus {
  PROCESSING = 'PROCESSING',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
  ERROR = 'ERROR',
  UNPROCESSED = 'UNPROCESSED',
}
/**
 *
 * @export
 * @enum {string}
 */
export enum DocumentType {
  budget = 'budget',
  'cms1500 claim form' = 'cms1500 claim form',
  'discharge summary' = 'discharge summary',
  'drivers license' = 'drivers license',
  email = 'email',
  'file folder' = 'file folder',
  form = 'form',
  handwritten = 'handwritten',
  specification = 'specification',
  'insurance id' = 'insurance id',
  invoice = 'invoice',
  letter = 'letter',
  'medical test' = 'medical test',
  'medical transcription' = 'medical transcription',
  memo = 'memo',
  'news article' = 'news article',
  'operative report' = 'operative report',
  'prescription' = 'prescription',
  presentation = 'presentation',
  questionnaire = 'questionnaire',
  resume = 'resume',
  'scientific publication' = 'scientific publication',
  'scientific report' = 'scientific report',
  'certificate of incorporation' = 'certificate of incorporation',
  'certificate incorporation report' = 'certificate incorporation report',
  undefined = 'undefined',
}
export enum UserStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  ERROR = 'ERROR',
}
export enum UserRole {
  Admin = 'admin',
  Editor = 'editor',
}
/**
 *
 * @export
 * @interface DocumentUpdate
 */
export interface DocumentUpdate {
  /**
   *
   * @type {string}
   * @memberof DocumentUpdate
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentUpdate
   */
  tags?: Array<string>;
  /**
   *
   * @type {DocumentStatus}
   * @memberof DocumentUpdate
   */
  status?: DocumentStatus;
  /**
   *
   * @type {DocumentStatus}
   * @memberof DocumentUpdate
   */
  documentClass: DocumentType | null;
  /**
   *
   * @type {Array<Pages>}
   * @memberof DocumentUpdate
   */
  pages?: LocalPage;
}
/**
 *
 * @export
 * @interface DocumentsResponse
 */
export interface DocumentsResponse {
  /**
   *
   * @type {number}
   * @memberof DocumentsResponse
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof DocumentsResponse
   */
  totalDocuments?: number;
  /**
   *
   * @type {number}
   * @memberof DocumentsResponse
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof DocumentsResponse
   */
  size?: number;
  /**
   *
   * @type {Array<Document>}
   * @memberof DocumentsResponse
   */
  content: DocumentWithAssignedUser[];

  meta: {
    statuses: Record<DocumentStatus, number>;
    tags: Record<string, number>;
    types: Partial<Record<DocumentType, number>>;
  };
}
/**
 *
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
  /**
   *
   * @type {string}
   * @memberof ErrorMessage
   */
  message: string;
}
/**
 *
 * @export
 * @interface Forms
 */
export interface Forms {
  /**
   *
   * @type {number}
   * @memberof Forms
   */
  id: number | null;
  /**
   *
   * @type {string}
   * @memberof Forms
   */
  keyText: string;
  /**
   *
   * @type {Array<number>}
   * @memberof Forms
   */
  keyBbox: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof Forms
   */
  valueText: string;
  /**
   *
   * @type {Array<number>}
   * @memberof Forms
   */
  valueBbox: Array<number> | null;
  /**
   *
   * @type {number}
   * @memberof Forms
   */
  index: number;
}
/**
 *
 * @export
 * @interface Group
 */
export interface Group {
  /**
   *
   * @type {number}
   * @memberof Group
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Group
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof Group
   */
  active?: boolean;
}
/**
 *
 * @export
 * @interface Items
 */
export interface Items {
  /**
   *
   * @type {number}
   * @memberof Items
   */
  id: number | null;
  /**
   *
   * @type {string}
   * @memberof Items
   */
  query: string;
  /**
   *
   * @type {boolean}
   * @memberof Items
   */
  single: boolean;
  /**
   *
   * @type {number}
   * @memberof Items
   */
  index: number;
  /**
   *
   * @type {Array<ItemsInfo>}
   * @memberof Items
   */
  itemsInfo: Array<ItemsInfo>;
}
/**
 *
 * @export
 * @interface ItemsInfo
 */
export interface ItemsInfo {
  /**
   *
   * @type {number}
   * @memberof ItemsInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ItemsInfo
   */
  text: string;
  labels: Array<string> | null;
  /**
   *
   * @type {Array<number>}
   * @memberof ItemsInfo
   */
  bbox: Array<number> | null;
  /**
   *
   * @type {number}
   * @memberof ItemsInfo
   */
  confidence: number | null;
}
/**
 *
 * @export
 * @interface Lines
 */
export interface Lines {
  /**
   *
   * @type {number}
   * @memberof Lines
   */
  id: number;
  /**
   *
   * @type {Array<number>}
   * @memberof Lines
   */
  bbox: Array<number>;
  /**
   *
   * @type {string}
   * @memberof Lines
   */
  text: string;
  /**
   *
   * @type {number}
   * @memberof Lines
   */
  index: number;
}

/**
 * Temporary interface for new schema of tables (need to switch it with original when response will be modified)
 */
export interface TablesInterface {
  cells: Cells[];
  tableIndex: number;
}
/**
 *
 * @export
 * @interface Cells
 */
export interface Cells {
  /**
   *
   * @type {number}
   * @memberof Cells
   */
  rowId: number;
  /**
   *
   * @type {number}
   * @memberof Cells
   */
  columnId: number;
  /**
   *
   * @type {number}
   * @memberof Cells
   */
  pageId: number;
  /**
   *
   * @type {number}
   * @memberof Cells
   */
  tableIndex: number;
  /**
   *
   * @type {Array<number>}
   * @memberof Cells
   */
  bbox: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof Cells
   */
  text: string;
}

/**
 *
 * @export
 * @interface Page
 */
export interface Page {
  /**
   *
   * @type {number}
   * @memberof Page
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Page
   */
  text: string;
  /**
   *
   * @type {Array<Words>}
   * @memberof Page
   */
  words?: Array<Words>;
  /**
   *
   * @type {Array<Lines>}
   * @memberof Page
   */
  lines?: Array<Lines>;
  /**
   *
   * @type {Array<Forms>}
   * @memberof Page
   */
  forms?: Array<Forms>;
  /**
   *
   * @type {Array<Cells>}
   * @memberof Page
   */
  tables?: Array<TablesInterface>;
  /**
   *
   * @type {Array<Items>}
   * @memberof Page
   */
  items?: Array<Items>;
}
/**
 *
 * @export
 * @interface Pages
 */
// @ts-ignore
export interface Pages {
  [key: string]: any;
}
/**
 *
 * @export
 * @interface PresignedUrl
 */
export interface PresignedUrl {
  /**
   *
   * @type {string}
   * @memberof PresignedUrl
   */
  url: string;
}

export enum ModelTypes {
  google_cloud = 'Google Cloud',
  aws_textract = 'AWS Textract',
  custom_textract = 'Provectus Textract',
  custom_tesseract = 'Provectus Tesseract',
  aws_textract_invoice_parser = 'AWS Textract Invoice',
  azure = 'Azure',
  'fda-483' = 'FDA 483',
  forge = 'Forge',
  all_models = 'All models',
}

export enum ProjectStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}
/**
 *
 * @export
 * @interface Project
 */
export interface Project {
  /**
   *
   * @type {number}
   * @memberof Project
   */
  id: number | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  name: string;
  displayName: string;
  clientId: number | null;
  modelIconUrl: string;
  modelUrl: string;
  modelId: number;
  useCaseId: number | null;
  /**
   *
   * @type {Client}
   * @memberof Project
   */
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  status?: ProjectStatus;
  modelUpdatedAt?: number;
  documentCounts: Partial<Record<DocumentStatus, number>>;
  /**
   *
   * @type {number}
   * @memberof Project
   */
  verifyThreshold: number;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  username: string;
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {Array<Group>}
   * @memberof User
   */
  groups?: Array<Group>;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  active?: boolean;
  role: UserRole;
  status: UserStatus;
  assignedProjectIDs?: Array<number>;
}
/**
 *
 * @export
 * @interface Words
 */
export interface Words {
  /**
   *
   * @type {number}
   * @memberof Words
   */
  id: number;
  /**
   *
   * @type {Array<number>}
   * @memberof Words
   */
  bbox: Array<number>;
  /**
   *
   * @type {string}
   * @memberof Words
   */
  text: string;
  /**
   *
   * @type {number}
   * @memberof Words
   */
  index: number;
}

export interface UseCaseSettings {
  classificationEnabled: boolean;
  showClassifiedText: boolean;
}
/**
 * ClientsApi - fetch parameter creator
 * @export
 */
export const ClientsApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary get clients that matched filter
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClients(name?: string, options: any = {}): FetchArgs {
      const localVarPath = `/api/client`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter.name = name;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      // @ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary get clients that matched filter
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClients(name?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Client>> {
      const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).getClients(name, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          }
          throw response;
        });
      };
    },
  };
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary get clients that matched filter
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClients(name?: string, options?: any) {
      return ClientsApiFp(configuration).getClients(name, options)(fetch, basePath);
    },
  };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
  /**
   *
   * @summary get clients that matched filter
   * @param {string} [name]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientsApi
   */
  public getClients(name?: string, options?: any) {
    return ClientsApiFp(this.configuration).getClients(name, options)(this.fetch, this.basePath);
  }
}
/**
 * DocumentsApi - fetch parameter creator
 * @export
 */
export const DocumentsApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get presigned url to download document directly from s3 bucket
     * @summary get presigned url to download document
     * @param {string} client client name
     * @param {string} project projects name
     * @param {string} document document file name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentDownload(client: string, project: string, document: string, options: any = {}): FetchArgs {
      // verify required parameter 'client' is not null or undefined
      if (client === null || client === undefined) {
        throw new RequiredError(
          'client',
          'Required parameter client was null or undefined when calling documentDownload.'
        );
      }
      // verify required parameter 'projects' is not null or undefined
      if (project === null || project === undefined) {
        throw new RequiredError(
          'project',
          'Required parameter projects was null or undefined when calling documentDownload.'
        );
      }
      // verify required parameter 'document' is not null or undefined
      if (document === null || document === undefined) {
        throw new RequiredError(
          'document',
          'Required parameter document was null or undefined when calling documentDownload.'
        );
      }
      const localVarPath = `/api/document/{client}/{project}/{document}/download`
        .replace(`{${'client'}}`, encodeURIComponent(String(client)))
        .replace(`{${'project'}}`, encodeURIComponent(String(project)))
        .replace(`{${'document'}}`, encodeURIComponent(String(document)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      // @ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get presigned url to upload document directly to s3 bucket
     * @summary get presigned url to upload document
     * @param {string} client client name
     * @param {string} project projects name
     * @param {string} document document file name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentUpload(client: string, project: string, document: string, options: any = {}): FetchArgs {
      // verify required parameter 'client' is not null or undefined
      if (client === null || client === undefined) {
        throw new RequiredError(
          'client',
          'Required parameter client was null or undefined when calling documentUpload.'
        );
      }
      // verify required parameter 'projects' is not null or undefined
      if (project === null || project === undefined) {
        throw new RequiredError(
          'project',
          'Required parameter projects was null or undefined when calling documentUpload.'
        );
      }
      // verify required parameter 'document' is not null or undefined
      if (document === null || document === undefined) {
        throw new RequiredError(
          'document',
          'Required parameter document was null or undefined when calling documentUpload.'
        );
      }
      const localVarPath = `/api/document/{client}/{project}/{document}/upload`
        .replace(`{${'client'}}`, encodeURIComponent(String(client)))
        .replace(`{${'project'}}`, encodeURIComponent(String(project)))
        .replace(`{${'document'}}`, encodeURIComponent(String(document)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      // @ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Process document by id
     * @summary process document by id
     * @param {number} documentId document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processDocument(documentId: number, options: any = {}): FetchArgs {
      // verify required parameter 'documentId' is not null or undefined
      if (documentId === null || documentId === undefined) {
        throw new RequiredError(
          'documentId',
          'Required parameter documentId was null or undefined when calling processDocument.'
        );
      }
      const localVarPath = `/api/document/{documentId}`.replace(
        `{${'documentId'}}`,
        encodeURIComponent(String(documentId))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = { method: 'POST', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      // @ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * By passing in the ids of documents, you can review them
     * @summary get documents for review by id
     * @param {number} documentId document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewDocument(documentId: number, options: any = {}): FetchArgs {
      // verify required parameter 'documentId' is not null or undefined
      if (documentId === null || documentId === undefined) {
        throw new RequiredError(
          'documentId',
          'Required parameter documentId was null or undefined when calling reviewDocument.'
        );
      }
      const localVarPath = `/api/document/{documentId}/review`.replace(
        `{${'documentId'}}`,
        encodeURIComponent(String(documentId))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      // @ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get random document for projects
     * @summary get random document for projects
     * @param {number} projectId projects id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewRandomDocument(projectId: number, options: any = {}): FetchArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling reviewRandomDocument.'
        );
      }
      const localVarPath = `/api/document/{projectId}/review/random`.replace(
        `{${'projectId'}}`,
        encodeURIComponent(String(projectId))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      // @ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * By passing in the appropriate parameters, you can search for available documents in the system
     * @summary searches documents
     * @param {string} [name] document name
     * @param {string} [tags] document tags
     * @param {Date} [startDate] Start date
     * @param {Date} [endDate] End date
     * @param {number} [page] Page number
     * @param {number} [limit] Maximum number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchDocument(
      name?: string,
      tags?: string,
      startDate?: Date,
      endDate?: Date,
      page?: number,
      limit?: number,
      options: any = {}
    ): FetchArgs {
      const localVarPath = `/api/document`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter.name = name;
      }

      if (tags !== undefined) {
        localVarQueryParameter.tags = tags;
      }

      if (startDate !== undefined) {
        localVarQueryParameter.startDate = (startDate as any).toISOString();
      }

      if (endDate !== undefined) {
        localVarQueryParameter.endDate = (endDate as any).toISOString();
      }

      if (page !== undefined) {
        localVarQueryParameter.page = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter.limit = limit;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      // @ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update document by id
     * @summary update document by id
     * @param {DocumentUpdate} body
     * @param {number} documentId document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDocument(body: DocumentUpdate, documentId: number, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateDocument.');
      }
      // verify required parameter 'documentId' is not null or undefined
      if (documentId === null || documentId === undefined) {
        throw new RequiredError(
          'documentId',
          'Required parameter documentId was null or undefined when calling updateDocument.'
        );
      }
      const localVarPath = `/api/document/{documentId}`.replace(
        `{${'documentId'}}`,
        encodeURIComponent(String(documentId))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = { method: 'PUT', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      // @ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>'DocumentUpdate' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Get presigned url to download document directly from s3 bucket
     * @summary get presigned url to download document
     * @param {string} client client name
     * @param {string} project projects name
     * @param {string} document document file name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentDownload(
      client: string,
      project: string,
      document: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PresignedUrl>> {
      const localVarFetchArgs = DocumentsApiFetchParamCreator(configuration).documentDownload(
        client,
        project,
        document,
        options
      );
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          }
          throw response;
        });
      };
    },
    /**
     * Get presigned url to upload document directly to s3 bucket
     * @summary get presigned url to upload document
     * @param {string} client client name
     * @param {string} project projects name
     * @param {string} document document file name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentUpload(
      client: string,
      project: string,
      document: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<PresignedUrl> {
      const localVarFetchArgs = DocumentsApiFetchParamCreator(configuration).documentUpload(
        client,
        project,
        document,
        options
      );
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          }
          throw response;
        });
      };
    },
    /**
     * Process document by id
     * @summary process document by id
     * @param {number} documentId document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processDocument(
      documentId: number,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Document>> {
      const localVarFetchArgs = DocumentsApiFetchParamCreator(configuration).processDocument(documentId, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          }
          throw response;
        });
      };
    },
    /**
     * By passing in the ids of documents, you can review them
     * @summary get documents for review by id
     * @param {number} documentId document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewDocument(
      documentId: number,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Document>> {
      const localVarFetchArgs = DocumentsApiFetchParamCreator(configuration).reviewDocument(documentId, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          }
          throw response;
        });
      };
    },
    /**
     * Get random document for projects
     * @summary get random document for projects
     * @param {number} projectId projects id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewRandomDocument(
      projectId: number,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Document>> {
      const localVarFetchArgs = DocumentsApiFetchParamCreator(configuration).reviewRandomDocument(projectId, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          }
          throw response;
        });
      };
    },
    /**
     * By passing in the appropriate parameters, you can search for available documents in the system
     * @summary searches documents
     * @param {string} [name] document name
     * @param {string} [tags] document tags
     * @param {Date} [startDate] Start date
     * @param {Date} [endDate] End date
     * @param {number} [page] Page number
     * @param {number} [limit] Maximum number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchDocument(
      name?: string,
      tags?: string,
      startDate?: Date,
      endDate?: Date,
      page?: number,
      limit?: number,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DocumentsResponse>> {
      const localVarFetchArgs = DocumentsApiFetchParamCreator(configuration).searchDocument(
        name,
        tags,
        startDate,
        endDate,
        page,
        limit,
        options
      );
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          }
          throw response;
        });
      };
    },
    /**
     * Update document by id
     * @summary update document by id
     * @param {DocumentUpdate} body
     * @param {number} documentId document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDocument(
      body: DocumentUpdate,
      documentId: number,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Document>> {
      const localVarFetchArgs = DocumentsApiFetchParamCreator(configuration).updateDocument(body, documentId, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          }
          throw response;
        });
      };
    },
  };
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     * Get presigned url to download document directly from s3 bucket
     * @summary get presigned url to download document
     * @param {string} client client name
     * @param {string} project projects name
     * @param {string} document document file name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentDownload(client: string, project: string, document: string, options?: any) {
      return DocumentsApiFp(configuration).documentDownload(client, project, document, options)(fetch, basePath);
    },
    /**
     * Get presigned url to upload document directly to s3 bucket
     * @summary get presigned url to upload document
     * @param {string} client client name
     * @param {string} project projects name
     * @param {string} document document file name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentUpload(client: string, project: string, document: string, options?: any) {
      return DocumentsApiFp(configuration).documentUpload(client, project, document, options)(fetch, basePath);
    },
    /**
     * Process document by id
     * @summary process document by id
     * @param {number} documentId document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processDocument(documentId: number, options?: any) {
      return DocumentsApiFp(configuration).processDocument(documentId, options)(fetch, basePath);
    },
    /**
     * By passing in the ids of documents, you can review them
     * @summary get documents for review by id
     * @param {number} documentId document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewDocument(documentId: number, options?: any) {
      return DocumentsApiFp(configuration).reviewDocument(documentId, options)(fetch, basePath);
    },
    /**
     * Get random document for projects
     * @summary get random document for projects
     * @param {number} projectId projects id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewRandomDocument(projectId: number, options?: any) {
      return DocumentsApiFp(configuration).reviewRandomDocument(projectId, options)(fetch, basePath);
    },
    /**
     * By passing in the appropriate parameters, you can search for available documents in the system
     * @summary searches documents
     * @param {string} [name] document name
     * @param {string} [tags] document tags
     * @param {Date} [startDate] Start date
     * @param {Date} [endDate] End date
     * @param {number} [page] Page number
     * @param {number} [limit] Maximum number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchDocument(
      name?: string,
      tags?: string,
      startDate?: Date,
      endDate?: Date,
      page?: number,
      limit?: number,
      options?: any
    ) {
      return DocumentsApiFp(configuration).searchDocument(
        name,
        tags,
        startDate,
        endDate,
        page,
        limit,
        options
      )(fetch, basePath);
    },
    /**
     * Update document by id
     * @summary update document by id
     * @param {DocumentUpdate} body
     * @param {number} documentId document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDocument(body: DocumentUpdate, documentId: number, options?: any) {
      return DocumentsApiFp(configuration).updateDocument(body, documentId, options)(fetch, basePath);
    },
  };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
  /**
   * Get presigned url to download document directly from s3 bucket
   * @summary get presigned url to download document
   * @param {string} client client name
   * @param {string} project projects name
   * @param {string} document document file name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public documentDownload(client: string, project: string, document: string, options?: any) {
    return DocumentsApiFp(this.configuration).documentDownload(
      client,
      project,
      document,
      options
    )(this.fetch, this.basePath);
  }

  /**
   * Get presigned url to upload document directly to s3 bucket
   * @summary get presigned url to upload document
   * @param {string} client client name
   * @param {string} project projects name
   * @param {string} document document file name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public documentUpload(client: string, project: string, document: string, options?: any) {
    return DocumentsApiFp(this.configuration).documentUpload(
      client,
      project,
      document,
      options
    )(this.fetch, this.basePath);
  }

  /**
   * Process document by id
   * @summary process document by id
   * @param {number} documentId document id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public processDocument(documentId: number, options?: any) {
    return DocumentsApiFp(this.configuration).processDocument(documentId, options)(this.fetch, this.basePath);
  }

  /**
   * By passing in the ids of documents, you can review them
   * @summary get documents for review by id
   * @param {number} documentId document id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public reviewDocument(documentId: number, options?: any) {
    return DocumentsApiFp(this.configuration).reviewDocument(documentId, options)(this.fetch, this.basePath);
  }

  /**
   * Get random document for projects
   * @summary get random document for projects
   * @param {number} projectId projects id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public reviewRandomDocument(projectId: number, options?: any) {
    return DocumentsApiFp(this.configuration).reviewRandomDocument(projectId, options)(this.fetch, this.basePath);
  }

  /**
   * By passing in the appropriate parameters, you can search for available documents in the system
   * @summary searches documents
   * @param {string} [name] document name
   * @param {string} [tags] document tags
   * @param {Date} [startDate] Start date
   * @param {Date} [endDate] End date
   * @param {number} [page] Page number
   * @param {number} [limit] Maximum number of items to return
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public searchDocument(
    name?: string,
    tags?: string,
    startDate?: Date,
    endDate?: Date,
    page?: number,
    limit?: number,
    options?: any
  ) {
    return DocumentsApiFp(this.configuration).searchDocument(
      name,
      tags,
      startDate,
      endDate,
      page,
      limit,
      options
    )(this.fetch, this.basePath);
  }

  /**
   * Update document by id
   * @summary update document by id
   * @param {DocumentUpdate} body
   * @param {number} documentId document id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public updateDocument(body: DocumentUpdate, documentId: number, options?: any) {
    return DocumentsApiFp(this.configuration).updateDocument(body, documentId, options)(this.fetch, this.basePath);
  }
}
/**
 * ProjectsApi - fetch parameter creator
 * @export
 */
export const ProjectsApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary get projects that matched filter
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjects(name?: string, options: any = {}): FetchArgs {
      const localVarPath = `/api/project`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter.name = name;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      // @ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary get projects that matched filter
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjects(name?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Project>> {
      const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).getProjects(name, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          }
          throw response;
        });
      };
    },
  };
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary get projects that matched filter
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjects(name?: string, options?: any) {
      return ProjectsApiFp(configuration).getProjects(name, options)(fetch, basePath);
    },
  };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
  /**
   *
   * @summary get projects that matched filter
   * @param {string} [name]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public getProjects(name?: string, options?: any) {
    return ProjectsApiFp(this.configuration).getProjects(name, options)(this.fetch, this.basePath);
  }
}
/**
 * UsersApi - fetch parameter creator
 * @export
 */
export const UsersApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get user by id
     * @summary get user by id
     * @param {number} userId User id
     * @param {boolean} [attachGroups] User groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserById(userId: number, attachGroups?: boolean, options: any = {}): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserById.');
      }
      const localVarPath = `/api/user/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (attachGroups !== undefined) {
        localVarQueryParameter.attachGroups = attachGroups;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      // @ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get user by id
     * @summary get users
     * @param {string} [firstName] User first name
     * @param {string} [lastName] User last name
     * @param {string} [email] User email
     * @param {boolean} [attachGroups] User groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(
      firstName?: string,
      lastName?: string,
      email?: string,
      attachGroups?: boolean,
      options: any = {}
    ): FetchArgs {
      const localVarPath = `/api/user`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (firstName !== undefined) {
        localVarQueryParameter.firstName = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter.lastName = lastName;
      }

      if (email !== undefined) {
        localVarQueryParameter.email = email;
      }

      if (attachGroups !== undefined) {
        localVarQueryParameter.attachGroups = attachGroups;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      // @ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Get user by id
     * @summary get user by id
     * @param {number} userId User id
     * @param {boolean} [attachGroups] User groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserById(
      userId: number,
      attachGroups?: boolean,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
      const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getUserById(userId, attachGroups, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          }
          throw response;
        });
      };
    },
    /**
     * Get user by id
     * @summary get users
     * @param {string} [firstName] User first name
     * @param {string} [lastName] User last name
     * @param {string} [email] User email
     * @param {boolean} [attachGroups] User groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(
      firstName?: string,
      lastName?: string,
      email?: string,
      attachGroups?: boolean,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<User>> {
      const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getUsers(
        firstName,
        lastName,
        email,
        attachGroups,
        options
      );
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          }
          throw response;
        });
      };
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     * Get user by id
     * @summary get user by id
     * @param {number} userId User id
     * @param {boolean} [attachGroups] User groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserById(userId: number, attachGroups?: boolean, options?: any) {
      return UsersApiFp(configuration).getUserById(userId, attachGroups, options)(fetch, basePath);
    },
    /**
     * Get user by id
     * @summary get users
     * @param {string} [firstName] User first name
     * @param {string} [lastName] User last name
     * @param {string} [email] User email
     * @param {boolean} [attachGroups] User groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(firstName?: string, lastName?: string, email?: string, attachGroups?: boolean, options?: any) {
      return UsersApiFp(configuration).getUsers(firstName, lastName, email, attachGroups, options)(fetch, basePath);
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   * Get user by id
   * @summary get user by id
   * @param {number} userId User id
   * @param {boolean} [attachGroups] User groups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserById(userId: number, attachGroups?: boolean, options?: any) {
    return UsersApiFp(this.configuration).getUserById(userId, attachGroups, options)(this.fetch, this.basePath);
  }

  /**
   * Get user by id
   * @summary get users
   * @param {string} [firstName] User first name
   * @param {string} [lastName] User last name
   * @param {string} [email] User email
   * @param {boolean} [attachGroups] User groups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUsers(firstName?: string, lastName?: string, email?: string, attachGroups?: boolean, options?: any) {
    return UsersApiFp(this.configuration).getUsers(
      firstName,
      lastName,
      email,
      attachGroups,
      options
    )(this.fetch, this.basePath);
  }
}
