import { createAsyncThunk } from '@reduxjs/toolkit';
import { Document, DocumentStatus, DocumentUpdate } from 'generatedSources';
import { RootState } from 'redux/store';
import { getCurrentPage } from 'redux/document/review';
import {
  getCurrentStep,
  getLastVisitedDocumentsListQueries,
  getTotal,
  setCurrentFoundedTotalDocuments,
  setCurrentStep,
  setEntities,
  setStatusesAndItsCount,
  setTypesAndItsCount,
  setTotal,
} from 'redux/document/list';
import { getClientId } from 'redux/login';
import { getRequestsSetters } from 'redux/ui';
import * as api from 'lib/api/documents';
import { parseQuery } from 'lib/api/helpers';
import { DocumentsProcessingStep } from 'types/documents';
import {
  DocumentFetchQueries,
  DocumentListProcessedResponse,
  DocumentWebSocketResponse,
  FetchDocumentsProps,
  UpdateDocumentsEntitiesPayload,
} from './types';
import * as actions from './actions';
import { getCurrentFoundedTotalDocuments, getEntities, getStatusesAndItsCount, getTypesAndItsCount } from './selectors';
import {
  filterDocumentsByQueryStatuses,
  getAllDocumentsCountByStatus,
  takeIntoAccountOldAndNewStatuses,
  takeIntoAccountOldAndNewTypes,
} from './helpers';
import {
  deleteDocumentsAction,
  excludeDocumentsListParamAction,
  fetchDocumentsAction,
  fetchListOfPredefinedClassesAction,
  updateDocumentTypeAction,
} from './actions';

const setNewStatusesAndItsCount = createAsyncThunk<void, Array<DocumentWebSocketResponse>, { state: RootState }>(
  actions.setNewStatusesAndItsCountAction,
  (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const statusesAndItsCount = getStatusesAndItsCount(state);

    const countOfAllDocumentsByItsStatuses = takeIntoAccountOldAndNewStatuses(payload, statusesAndItsCount);
    thunkAPI.dispatch(setStatusesAndItsCount(countOfAllDocumentsByItsStatuses));
  }
);

const setNewTypesAndItsCount = createAsyncThunk<void, Array<DocumentWebSocketResponse>, { state: RootState }>(
  actions.setNewStatusesAndItsCountAction,
  (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const typesAndItsCount = getTypesAndItsCount(state);

    const countOfAllDocumentsByItsType = takeIntoAccountOldAndNewTypes(payload, typesAndItsCount);
    thunkAPI.dispatch(setTypesAndItsCount(countOfAllDocumentsByItsType));
  }
);

const updateDocumentsWithoutStatusFiltration = createAsyncThunk<
  void,
  Array<DocumentWebSocketResponse>,
  { state: RootState }
>(actions.excludeExcessDocumentsWithProcessingStatusAction, (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const entities = getEntities(state);
  const updatedEntities = entities.map((entity) => {
    const updatedDocumentInTheList = payload.find((updatedDocument) => updatedDocument.id === entity.id);
    return updatedDocumentInTheList || entity;
  });
  thunkAPI.dispatch(setEntities(updatedEntities));
  thunkAPI.dispatch(setNewStatusesAndItsCount(payload));
  thunkAPI.dispatch(setNewTypesAndItsCount(payload));
});

export const insertNewEntitiesOnTheCurrentPage = createAsyncThunk<
  void,
  Array<DocumentWebSocketResponse>,
  { state: RootState }
>(actions.updateDocumentsEntitiesAction, async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const countOfNewDocuments = payload.length;
  const entities = getEntities(state);
  const newEntities = [...entities];
  const currentFoundedTotalDocuments = getCurrentFoundedTotalDocuments(state);

  if (currentFoundedTotalDocuments >= 10) {
    newEntities.splice(entities.length - countOfNewDocuments, countOfNewDocuments);
    thunkAPI.dispatch(setEntities([...payload, ...newEntities]));
  }
  if (currentFoundedTotalDocuments < 10) {
    thunkAPI.dispatch(setEntities([...payload, ...newEntities]));
  }
  thunkAPI.dispatch(setNewStatusesAndItsCount(payload));
  thunkAPI.dispatch(setCurrentFoundedTotalDocuments(currentFoundedTotalDocuments + countOfNewDocuments));
});

export const fetchListOfPredefinedClasses = createAsyncThunk<
  Array<string>,
  { projectID: number },
  { rejectValue: Array<string> }
>(fetchListOfPredefinedClassesAction, async (payload, thunkAPI) => {
  const { setFailed, setPending, setSuccess } = getRequestsSetters('FETCH_LIST_OF_PREDEFINED_CLASSES');
  const state = thunkAPI.getState() as RootState;
  const { dispatch } = thunkAPI;
  const clientId = getClientId(state) as number;
  const { projectID } = payload;
  try {
    dispatch(setPending());
    const listOfPredefinedClasses = await api.fetchListOfPredefinedClasses(clientId, projectID);
    dispatch(setSuccess());
    return Array.from(new Set(listOfPredefinedClasses));
  } catch (e) {
    dispatch(setFailed());
    return thunkAPI.rejectWithValue([]);
  }
});

export const fetchDocuments = createAsyncThunk<
  DocumentListProcessedResponse | null | Error,
  FetchDocumentsProps,
  { state: RootState }
>(fetchDocumentsAction, async (payload, thunkAPI) => {
  const { setFailed, setPending, setSuccess } = getRequestsSetters('FETCH_DOCUMENTS');
  const { projectId } = payload.queries;
  const { documentIDs } = payload;
  const { dispatch } = thunkAPI;
  dispatch(setPending());
  if (!projectId) {
    dispatch(setFailed());
    return null;
  }
  try {
    const response = await api.fetchDocuments({
      ...payload.queries,
      projectId,
      deletedDocsIds: documentIDs,
    });
    dispatch(fetchListOfPredefinedClasses({ projectID: projectId }));
    const { totalElements, totalDocuments, meta, content } = response;
    const totalDocumentsByStatuses = getAllDocumentsCountByStatus(meta.statuses);
    const getTotalDocumentsFromDifferentSources = totalDocumentsByStatuses || totalDocuments || totalElements;
    const currentStep = getTotalDocumentsFromDifferentSources
      ? DocumentsProcessingStep.Categorize
      : DocumentsProcessingStep.Upload;
    dispatch(setSuccess());
    return {
      total: totalDocuments || 0,
      currentFoundedTotalDocuments: totalElements || 0,
      tagsAndItsCount: meta.tags || {},
      statusesAndItsCount: meta.statuses || {},
      typesAndItsCount: meta.types || {},
      entities: content || [],
      currentStep,
    };
  } catch (e) {
    dispatch(setFailed());
    const error = e as Error;
    return error;
  }
});

export const deleteDocuments = createAsyncThunk<
  void,
  { queries: DocumentFetchQueries; documentIDs: number[] },
  { state: RootState }
>(deleteDocumentsAction, async (payload, thunkAPI) => {
  await api.deleteDocument(payload.documentIDs);

  thunkAPI.dispatch(fetchDocuments(payload));
});

export const excludeDocumentsListParam = createAsyncThunk<string | null, { param: string }, { state: RootState }>(
  excludeDocumentsListParamAction,
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const lastVisitedDocumentsListQueries = getLastVisitedDocumentsListQueries(state);
    if (lastVisitedDocumentsListQueries) {
      const queries = new URLSearchParams(lastVisitedDocumentsListQueries);
      if (queries.has(payload.param)) {
        queries.delete(payload.param);
        const newParams = queries.toString();
        return newParams;
      }
      return queries.toString();
    }
    return null;
  }
);

export const updateDocument = createAsyncThunk<
  Document,
  { id: Document['id']; documentWithoutId: DocumentUpdate; queries: DocumentFetchQueries },
  { state: RootState }
>(updateDocumentTypeAction, async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const entities = getEntities(state);
  const { id, documentWithoutId, queries } = payload;
  const response = await api.updateDocumentPatch(id, documentWithoutId);

  if (queries.documentType && queries.documentType.length > 0 && entities.length > 1) {
    const currentQuery =
      entities.length > 1 ? { ...queries, projectId: queries.projectId } : { projectId: queries.projectId };

    setTimeout(async () => {
      if (response) await thunkAPI.dispatch(fetchDocuments({ queries: currentQuery }));
    }, 1500);
  }
  return response;
});

const excludeExcessDocumentsWithProcessingStatus = createAsyncThunk<
  void,
  Array<DocumentWebSocketResponse>,
  { state: RootState }
>(actions.excludeExcessDocumentsWithProcessingStatusAction, (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const entities = getEntities(state);
  const currentFoundedTotalDocuments = getCurrentFoundedTotalDocuments(state);
  if (currentFoundedTotalDocuments > 10) {
    const queries = parseQuery(window.location.search);
    thunkAPI.dispatch(fetchDocuments({ queries: queries as unknown as DocumentFetchQueries }));
  } else {
    const entitiesWithoutProcessed = payload
      .map((updatedDocument) => entities.filter((entity) => entity.id !== updatedDocument.id))
      .flat();
    thunkAPI.dispatch(setEntities(entitiesWithoutProcessed));
  }
  thunkAPI.dispatch(setNewStatusesAndItsCount(payload));
});

const updateExistingDocumentsInTheList = createAsyncThunk<
  void,
  { updatingDocuments: DocumentWebSocketResponse[]; queryStatuses: DocumentStatus | null | undefined },
  { state: RootState }
>(actions.updateExistingDocumentInTheListAction, async (payload, thunkApi) => {
  const { updatingDocuments, queryStatuses } = payload;
  switch (queryStatuses) {
    case null:
      thunkApi.dispatch(updateDocumentsWithoutStatusFiltration(updatingDocuments));
      break;
    case 'PROCESSING':
      thunkApi.dispatch(excludeExcessDocumentsWithProcessingStatus(updatingDocuments));
      break;
    case 'UNVERIFIED': {
      const unverifiedDocumentsInTheUpdatedDocuments = filterDocumentsByQueryStatuses(
        updatingDocuments,
        DocumentStatus.UNVERIFIED
      );
      if (unverifiedDocumentsInTheUpdatedDocuments.length)
        thunkApi.dispatch(insertNewEntitiesOnTheCurrentPage(unverifiedDocumentsInTheUpdatedDocuments));
      break;
    }
    case 'VERIFIED': {
      const verifiedDocumentsInTheUpdatedDocuments = filterDocumentsByQueryStatuses(
        updatingDocuments,
        DocumentStatus.VERIFIED
      );
      if (verifiedDocumentsInTheUpdatedDocuments.length)
        thunkApi.dispatch(insertNewEntitiesOnTheCurrentPage(verifiedDocumentsInTheUpdatedDocuments));
      break;
    }
    case 'ERROR': {
      const documentsWithErrorInTheUpdatedDocuments = filterDocumentsByQueryStatuses(
        updatingDocuments,
        DocumentStatus.ERROR
      );
      if (documentsWithErrorInTheUpdatedDocuments.length)
        thunkApi.dispatch(insertNewEntitiesOnTheCurrentPage(documentsWithErrorInTheUpdatedDocuments));
      break;
    }
    default:
  }
});

export const handleWebSocketDocumentsUpdate = createAsyncThunk<
  void,
  UpdateDocumentsEntitiesPayload,
  { state: RootState }
>(actions.updateDocumentsEntitiesAction, async (payload, thunkAPI) => {
  const { operationType, response } = payload.newDocuments;
  const { queryStatuses } = payload;
  const state = thunkAPI.getState();
  const currentPage = getCurrentPage(state);
  const currentStep = getCurrentStep(state);
  if (currentStep !== DocumentsProcessingStep.Categorize)
    thunkAPI.dispatch(setCurrentStep(DocumentsProcessingStep.Categorize));
  const total = getTotal(state);

  if (operationType === 'UPDATE') {
    thunkAPI.dispatch(updateExistingDocumentsInTheList({ updatingDocuments: response, queryStatuses }));
    return;
  }
  const countOfNewDocuments = response.length;
  thunkAPI.dispatch(setTotal(total + countOfNewDocuments));

  if (currentPage === 1 && (!queryStatuses || queryStatuses === 'PROCESSING')) {
    thunkAPI.dispatch(insertNewEntitiesOnTheCurrentPage(response));
  } else {
    thunkAPI.dispatch(setNewStatusesAndItsCount(response));
  }
});
