import React, { FC } from 'react';
import { Button as AntdButton } from 'antd';
import classnames from 'classnames/bind';
import { BigOrSmall } from 'types/common';
import classes from './Button.module.scss';

export interface ButtonProps {
  disabled?: boolean;
  isSetDisabledClassName?: boolean;
  loading?: boolean;
  onClick?(event: React.MouseEvent): void;
  onMouseDown?(event: React.MouseEvent): void;
  icon?: React.ReactNode;
  type?: 'secondary' | 'default' | 'warning' | 'tertiary_warning' | 'tertiary' | 'icon' | 'square' | 'rectangle';
  classname?: string;
  dataTestID?: string;
  size?: BigOrSmall;
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
  iconColor?: 'black';
}

const cx = classnames.bind(classes);

const Button: FC<ButtonProps> = ({
  onClick,
  onMouseDown,
  size,
  icon,
  children,
  disabled = false,
  isSetDisabledClassName,
  loading = false,
  type,
  classname,
  dataTestID,
  htmlType,
  iconColor,
  ...props
}) => {
  if (type && type === 'icon') {
    return (
      <AntdButton
        data-testid={dataTestID}
        disabled={disabled}
        onClick={onClick}
        icon={icon}
        className={cx(
          'ButtonIcon',
          { __small: size === 'small' },
          { __blackIcon: iconColor === 'black' },
          { __disabled: isSetDisabledClassName },
          classname
        )}
      />
    );
  }

  return (
    <AntdButton
      data-testid={dataTestID}
      icon={icon}
      onClick={onClick}
      onMouseDown={onMouseDown}
      disabled={disabled}
      htmlType={htmlType}
      className={cx(
        'Button',
        classname,
        type,
        { __withIcon: icon, __small: size === 'small' },
        { __disabled: isSetDisabledClassName }
      )}
      loading={loading}
      {...props}
    >
      {children}
    </AntdButton>
  );
};

export default Button;
