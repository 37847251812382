import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestKey } from 'redux/ui/types';
import { UiState } from './types';

export const initialState: UiState = {
  requests: {} as UiState['requests'],
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setPending: (state: UiState, { payload }: PayloadAction<RequestKey>) => ({
      ...state,
      requests: { ...state.requests, [payload]: 'pending' },
    }),
    setSuccess: (state: UiState, { payload }: PayloadAction<RequestKey>) => ({
      ...state,
      requests: { ...state.requests, [payload]: 'success' },
    }),
    setFailed: (state: UiState, { payload }: PayloadAction<RequestKey>) => ({
      ...state,
      requests: { ...state.requests, [payload]: 'error' },
    }),
    setCanceled: (state: UiState, { payload }: PayloadAction<RequestKey>) => ({
      ...state,
      requests: { ...state.requests, [payload]: 'notPerformed' },
    }),
    resetRequestStatus: (state: UiState, { payload }: PayloadAction<RequestKey>) => {
      const requests = { ...state.requests };
      delete requests[payload];
      return {
        ...state,
        requests,
      };
    },
    resetStore: () => ({
      ...initialState,
    }),
  },
});

export const getRequestsSetters = (key: RequestKey) => ({
  setSuccess: () => slice.actions.setSuccess(key),
  setPending: () => slice.actions.setPending(key),
  setFailed: () => slice.actions.setFailed(key),
  setCanceled: () => slice.actions.setCanceled(key),
  resetRequest: () => slice.actions.resetRequestStatus(key),
});

export const { setFailed, setSuccess, setCanceled, setPending, resetStore } = slice.actions;

export * from './selectors';

export default slice.reducer;
