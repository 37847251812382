/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext } from 'react';
import { ConfirmContext, ConfirmState } from 'types/modals';

export const useConfirmMeta = (): ConfirmContext => {
  const [confirmState, setConfirmState] = React.useState<ConfirmState>({ text: '', textTitle: '', visible: false });

  return {
    confirmState,
    setConfirmState,
  };
};

const ConfirmMenuContext = createContext<ConfirmContext>({
  confirmState: { text: '', textTitle: '', visible: false },

  setConfirmState: () => {},
});

ConfirmMenuContext.displayName = 'ConfirmContext';

export function useConfirmContext() {
  const context = useContext(ConfirmMenuContext);
  if (!context) {
    throw new Error('DocumentsActionsContext must be used with DocumentsActionsContext.Provider!');
  }
  return context;
}

const ConfirmProvider = ({ children }: any) => {
  const documentMeta = useConfirmMeta();
  return <ConfirmMenuContext.Provider value={documentMeta}>{children}</ConfirmMenuContext.Provider>;
};

export const withConfirmProvider = (Component: any) => {
  return (props: any) => (
    <ConfirmProvider>
      <Component {...props} />
    </ConfirmProvider>
  );
};
