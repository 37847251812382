import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import ui from './ui';
import document from './document';
import login from './login';
import projects from './projects';
import loaders from './loaders';
import users from './users';

export const rootReducer = combineReducers({
  ui,
  document,
  login,
  projects,
  users,
  loaders,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
