import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { resetRequestState } from 'redux/loaders';
import { selectLoadingStateByName } from 'redux/loaders/selectors';
import { RootState } from 'redux/store';
import { useAppDispatch } from './useAppDispatch';
import { usePrevious } from './usePrevious';

export const useRequestState = (
  name: string,
  hooks?: { onFulfilled?: (result?: unknown) => void; onRejected?: (message?: string) => void }
) => {
  const dispatch = useAppDispatch();
  const requestState = useSelector((state: RootState) => selectLoadingStateByName(state, name));

  const isLoading = requestState.status === 'pending';

  const isFulfilled = requestState.status === 'fulfilled';
  const prevIsFulfilled = usePrevious(isFulfilled);

  const isRejected = requestState.status === 'rejected';
  const prevIsRejected = usePrevious(isRejected);

  useEffect(() => {
    return () => {
      dispatch(resetRequestState(name));
    };
  }, [dispatch, name]);

  useEffect(() => {
    if (isFulfilled && !prevIsFulfilled) {
      hooks?.onFulfilled?.(requestState.result);
    }
  }, [hooks, isFulfilled, name, prevIsFulfilled, requestState]);

  useEffect(() => {
    if (isRejected && !prevIsRejected) {
      hooks?.onRejected?.(requestState.error);
    }
  }, [hooks, isRejected, prevIsRejected, requestState.error]);

  return {
    isLoading,
    isLoaded: isFulfilled || isRejected,
    error: requestState.error,
    result: requestState.result,
  };
};
