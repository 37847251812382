import { createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { LoadersState } from './types';

const initialState: LoadersState = {
  requests: {},
};

const getPendingActionType = (action: PayloadAction<unknown>) => action.type.replace('/pending', '');

const getFulfilledActionType = (action: PayloadAction<unknown>) => action.type.replace('/fulfilled', '');

const getRejectedActionType = (action: PayloadAction<unknown>) => action.type.replace('/rejected', '');

const slice = createSlice({
  name: 'loaders',
  initialState,
  reducers: {
    resetRequestState(state, action: PayloadAction<string>) {
      delete state.requests[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isPending, (state, action) => {
      state.requests[getPendingActionType(action)] = { status: 'pending' };
    });
    builder.addMatcher(isFulfilled, (state, action) => {
      state.requests[getFulfilledActionType(action)] = { status: 'fulfilled', result: action.payload };
    });
    builder.addMatcher(isRejected, (state, action) => {
      state.requests[getRejectedActionType(action)] = { status: 'rejected', error: action.error.message };
    });
  },
});

export const {
  actions: { resetRequestState },
} = slice;

export default slice.reducer;
