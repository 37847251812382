import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { DocumentWithAssignedUser } from 'redux/document/review/types';
import { DocumentType, DocumentStatus } from 'generatedSources';
import { DocumentsProcessingStep } from 'types/documents';
import { DocumentListState } from './types';
import { getIsDisabled } from './helpers';

const list = (state: RootState): DocumentListState => state.document.list;

export const getTotal = (state: RootState): number => list(state).total;
export const getCurrentStep = (state: RootState): DocumentsProcessingStep => list(state).currentStep;
export const getDocumentCountWithProcessingOnes = (state: RootState): number =>
  list(state).documentCountWithProcessingOnes;
export const getCurrentFoundedTotalDocuments = (state: RootState): number => list(state).currentFoundedTotalDocuments;
export const getLastVisitedDocumentsListQueries = (state: RootState): string | null =>
  list(state).lastVisitedDocumentsListQueries;
export const getListOfPredefinedClasses = (state: RootState): Array<string> => list(state).listOfPredefinedClasses;
export const selectLastVisitedDocumentsListQueries = createSelector([getLastVisitedDocumentsListQueries], (queries) =>
  queries ? new URLSearchParams(queries) : queries
);

export const getStatusesAndItsCount = (state: RootState): Record<DocumentStatus, number> =>
  list(state).statusesAndItsCount;

export const getNumberOfVerifiedDocuments = (state: RootState): number => getStatusesAndItsCount(state).VERIFIED || 0;

export const getNumberOfUnverifiedDocuments = (state: RootState): number =>
  getStatusesAndItsCount(state).UNVERIFIED || 0;

export const getNumberOfProcessingDocuments = (state: RootState): number =>
  getStatusesAndItsCount(state).PROCESSING || 0;

export const getTypesAndItsCount = (state: RootState): Record<DocumentType, number> => list(state).typesAndItsCount;

export const getTagsAndItsCount = (state: RootState): Record<string, number> => list(state).tagsAndItsCount;
export const getEntities = (state: RootState): DocumentWithAssignedUser[] => list(state).entities || [];
export const getCurrentPageDocumentsCountExceptProcessingOnes = createSelector(
  getEntities,
  (documentsOnTheCurrentPage) => documentsOnTheCurrentPage.filter((document) => document.status !== 'PROCESSING')
);
export const getEntitiesNumber = (state: RootState): number => list(state).entities.length;

export const getEntityById = (state: RootState, id: number) => list(state).entities.find((entity) => entity.id === id);

export const getCurrentPageEntitiesIDs = (state: RootState) => list(state).entities.map((entity) => entity.id);

// TODO: When socket connection will be implemented, it will work correctly (now - not)
export const isAnyDocumentProcessing = createSelector(
  getTotal,
  getDocumentCountWithProcessingOnes,
  (total, processing) => (total || 0) < processing
);

export const isAnyProcessingOrErrorDocumentSelected = (selectedIDs: number[]) =>
  createSelector(
    getEntities,
    (entities) =>
      !!selectedIDs.find((ID) => entities.find((entity) => getIsDisabled(entity.status) && entity.id === ID))
  );

export const getCurrentFoundedDocumentsCountWithoutBeingProcessed = createSelector(
  getCurrentFoundedTotalDocuments,
  getNumberOfProcessingDocuments,
  (currentFoundedTotalDocuments, numberOfUnverifiedDocuments) => {
    const params = new URLSearchParams(window.location.search);
    const statuses = params.get('statuses');
    if (statuses) {
      return currentFoundedTotalDocuments;
    }
    return currentFoundedTotalDocuments - numberOfUnverifiedDocuments;
  }
);

// export const documentsWhichAreExistsOnTheCurrentPage = (IDs: number[]) =>
//   createSelector(getEntities, (entities) => {
//     const overlaps: DocumentWithAssignedUser[] = [];
//     for (let i = 0; i < IDs.length + 1; i += 1) {
//       overlaps.concat(entities.filter((entity) => entity.id === IDs[i]));
//     }
//     console.log(overlaps);
//   });
