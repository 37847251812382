import { Project, UseCaseSettings } from 'generatedSources';
import { Model, SingleUseCaseResponse, UseUseCaseResponse } from 'redux/projects/types';
import baseAPI from './baseAPI';
import { convertResponseToJSON } from './helpers';

export const fetchProjects = async (clientId: number, name?: string): Promise<Array<Project>> => {
  return convertResponseToJSON(await baseAPI(`/clients/${clientId}/projects`, { method: 'GET' }, { name }));
};

export const fetchSingleProject = async (clientId: number, projectId: number): Promise<Project> => {
  return convertResponseToJSON(await baseAPI(`/clients/${clientId}/projects/${projectId}`, { method: 'GET' }));
};

export const fetchModels = async (): Promise<Array<Model>> => {
  return convertResponseToJSON(await baseAPI(`/models`, { method: 'GET' }, {}));
};

export const updateProject = async (clientId: number, projectId: number, project: Project): Promise<Project> => {
  return convertResponseToJSON(
    await baseAPI(`/clients/${clientId}/projects/${projectId}`, { method: 'PUT', body: JSON.stringify(project) })
  );
};

export const createProject = async (
  clientId: number,
  project: Partial<Project>,
  signal?: AbortSignal
): Promise<void> => {
  await baseAPI(`/clients/${clientId}/projects`, { method: 'POST', body: JSON.stringify(project) }, {}, signal);
};

export const getUseCases = async (signal?: AbortSignal): Promise<Array<UseUseCaseResponse>> => {
  return convertResponseToJSON(await baseAPI(`/useCases`, { method: 'GET' }, {}, signal));
};

export const getProjectUseCaseSettings = async (
  projectID: number,
  clientID: number,
  signal?: AbortSignal
): Promise<UseCaseSettings> => {
  const settings = await convertResponseToJSON(
    await baseAPI(`/clients/${clientID}/projects/${projectID}/settings`, { method: 'GET' }, {}, signal)
  );
  return JSON.parse(settings);
};

export const getProjectUseCase = async (projectID: number, signal?: AbortSignal): Promise<SingleUseCaseResponse> => {
  try {
    return await baseAPI(`/useCases/${projectID}`, { method: 'GET' }, {}, signal).then((r) => r.json());
  } catch (e) {
    const error = e as Error;
    throw Error(error.message);
  }
};

export const archiveProject = async (clientId: number, projectId: number): Promise<Project> => {
  return convertResponseToJSON(await baseAPI(`/clients/${clientId}/projects/${projectId}`, { method: 'DELETE' }));
};
