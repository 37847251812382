import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequestsSetters } from 'redux/ui';
import { RootState } from 'redux/store';
import { getUserNamesByIDs, setRolesAndItsCount, setStatusesAndItsCount, setUsers } from 'redux/users';
import { checkAuthentication, getClientId } from 'redux/login';
import * as api from 'lib/api/users';
import { AssignAndUnAssignProjectsParams, UpdateUsersRolesParams } from 'types/users';
import { EditUserFormData } from 'components/Users/helpers';
import { FormValues } from 'types/modals';
import { User } from 'redux/login/types';
import { getCurrentProjectID, getCurrentProjectId } from 'redux/projects';
import { getRolesCounts, getStatusesCounts } from 'lib/helpers/rolesAndStatuses';
import { getEditingUser, getUserByID, getUsers } from './selectors';
import { FetchUsersProps, UsersListQueries } from './types';
import {
  fetchUsersAction,
  fetchUserProjectsAction,
  resetUserPasswordAction,
  setEditingUserByIDAction,
  saveEditingUserAction,
  inviteUserAction,
  updateUsersRolesAction,
  assignProjectsToUserAction,
  archiveUsersToClientAction,
  fetchMangeUsersAction,
} from './actions';

export const fetchUsers = createAsyncThunk(fetchUsersAction, async (payload: FetchUsersProps, thunkAPI) => {
  const { setFailed, setPending, setSuccess } = getRequestsSetters('FETCH_USERS');
  thunkAPI.dispatch(setPending());
  try {
    const users = await api.getUsers(payload);
    if (users) thunkAPI.dispatch(setUsers(users));

    thunkAPI.dispatch(setStatusesAndItsCount(getStatusesCounts(users)));
    thunkAPI.dispatch(setRolesAndItsCount(getRolesCounts(users)));
    thunkAPI.dispatch(setSuccess());
  } catch (e) {
    thunkAPI.dispatch(setFailed());
  }
});

export const fetchMangeUsers = createAsyncThunk(fetchMangeUsersAction, async (payload: UsersListQueries, thunkAPI) => {
  const { setFailed, setPending, setSuccess } = getRequestsSetters('FETCH_USERS');
  thunkAPI.dispatch(setPending());
  const state = thunkAPI.getState() as RootState;

  const clientId = getClientId(state) as number;
  const projectId = getCurrentProjectID(state) as number;

  try {
    const users = await api.getMangeUsers(payload, clientId, projectId);
    if (users) thunkAPI.dispatch(setUsers(users));
    thunkAPI.dispatch(setSuccess());
  } catch (e) {
    thunkAPI.dispatch(setFailed());
  }
});

export const fetchUserProjects = createAsyncThunk(fetchUserProjectsAction, async (id: number, thunkAPI) => {
  const { setFailed, setPending, setSuccess } = getRequestsSetters('FETCH_PROJECTS');
  thunkAPI.dispatch(setPending());

  try {
    const projects = await api.getUserProjects(id);
    thunkAPI.dispatch(setSuccess());
    return projects;
    return [];
  } catch (e) {
    thunkAPI.dispatch(setFailed());
    const error = e as Error;
    throw Error(error.message);
  }
});

export const resetUsersPassword = createAsyncThunk(resetUserPasswordAction, async (_, thunkAPI) => {
  const { setFailed, setPending, setSuccess } = getRequestsSetters('RESET_USER_PASSWORD');
  const state = thunkAPI.getState() as RootState;
  const editingUser = getEditingUser(state);
  if (editingUser) {
    thunkAPI.dispatch(setPending());
    try {
      await api.resetUserPassword(editingUser.username);
      thunkAPI.dispatch(setSuccess());
    } catch (e) {
      thunkAPI.dispatch(setFailed());
    }
  }
});

export const inviteNewUser = createAsyncThunk(inviteUserAction, async (values: FormValues, thunkAPI) => {
  const { setPending, setSuccess, setFailed } = getRequestsSetters('INVITE_USER');

  const state = thunkAPI.getState() as RootState;
  const { name } = state.login.user as User;
  const firstName = name && name.split(' ')[0];
  const lastName = name && name.split(' ')[1];

  try {
    thunkAPI.dispatch(setPending());
    await api.inviteUser({ firstName, lastName, values });
    thunkAPI.dispatch(setSuccess());
  } catch (e) {
    const error = e as Error;
    thunkAPI.dispatch(setFailed());
    throw new Error(error.message);
  }
});

export const saveEditingUser = createAsyncThunk(
  saveEditingUserAction,
  async (editingUserMeta: EditUserFormData, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { setFailed, setPending, setSuccess } = getRequestsSetters('SAVE_EDITING_USER');
    const clientId = getClientId(state);
    const usersList = getUsers(state);
    const editingUser = getEditingUser(state);
    if (usersList && editingUser && clientId) {
      try {
        thunkAPI.dispatch(setPending());
        const newUser = { ...editingUser, ...editingUserMeta };
        await api.updateUser(newUser);
        const newUsers = usersList?.map((user) => {
          if (user.id === editingUser?.id) return { ...editingUser, ...editingUserMeta };
          return user;
        });
        thunkAPI.dispatch(setUsers(newUsers));
        // TODO: I believe that roles and its count gonna be in response of updating user request.
        // thunkAPI.dispatch(setRolesAndItsCount(MockedUsersRolesAndItsCount));
        await thunkAPI.dispatch(checkAuthentication());
        thunkAPI.dispatch(setSuccess());
      } catch (e) {
        thunkAPI.dispatch(setFailed());
      }
    }
  }
);

export const updateUsersRoles = createAsyncThunk(
  updateUsersRolesAction,
  async ({ groupName, selectedUsersIDs }: UpdateUsersRolesParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { setFailed, setPending, setSuccess } = getRequestsSetters('UPDATE_USERS_ROLES');
    const selectedUserNames = getUserNamesByIDs(state)(selectedUsersIDs);
    try {
      thunkAPI.dispatch(setPending());
      const usersList = getUsers(state);
      await api.updateRolesOfUsers({ groupName, userNames: selectedUserNames });
      // TODO: Set new list of users w/ updated statuses.
      thunkAPI.dispatch(setUsers(usersList));
      thunkAPI.dispatch(setSuccess());
    } catch (e) {
      thunkAPI.dispatch(setFailed());
    }
  }
);

export const setEditingUserByID = createAsyncThunk(setEditingUserByIDAction, (editingUserID: number, thunkAPI) => {
  const editingUser = getUserByID(thunkAPI.getState() as RootState)(editingUserID);
  if (editingUser) {
    return editingUser;
  }
  return null;
});

export const assignProjectsToUser = createAsyncThunk(
  assignProjectsToUserAction,
  async (props: AssignAndUnAssignProjectsParams, thunkAPI) => {
    const { setPending, setSuccess, setFailed } = getRequestsSetters('ASSIGN_USER_TO_PROJECTS');
    const { id } = getEditingUser(thunkAPI.getState() as RootState)!;
    try {
      thunkAPI.dispatch(setPending());
      await api.assignUserToProject(id, props);
      thunkAPI.dispatch(setSuccess());
      return null;
    } catch (e) {
      const error = e as Error;
      thunkAPI.dispatch(setFailed());
      throw Error(error.message);
    }
  }
);

export const archiveUsersToClient = createAsyncThunk(archiveUsersToClientAction, async (ids: number[], thunkAPI) => {
  const { setPending, setSuccess, setFailed } = getRequestsSetters('ARCHIVE_USER_TO_CLIENTS');

  try {
    thunkAPI.dispatch(setPending());
    await api.archiveUser(ids);
    thunkAPI.dispatch(setSuccess());
    return null;
  } catch (e) {
    const error = e as Error;
    thunkAPI.dispatch(setFailed());
    throw Error(error.message);
  }
});

export const assignProjectManageUser = createAsyncThunk(saveEditingUserAction, async (props: number[], thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const { setFailed, setPending, setSuccess } = getRequestsSetters('ASSIGN_PROJECT_MANAGE_USER');
  const clientId = getClientId(state) as number;
  const projectId = getCurrentProjectId(state) as number;
  if (clientId) {
    try {
      thunkAPI.dispatch(setPending());
      await api.assignManageUsers(clientId, projectId, props);
      thunkAPI.dispatch(setSuccess());
    } catch (e) {
      thunkAPI.dispatch(setFailed());
    }
  }
});
