import React, { FC } from 'react';
import { Spin as AntdSpin } from 'antd';
import { SpinProps as AntdSpinProps } from 'antd/lib/spin';

export interface SpinProps extends AntdSpinProps {
  classname?: string;
  dataTestID?: string;
}

const Spin: FC<SpinProps> = ({ size, dataTestID, classname }) => (
  <AntdSpin data-testid={dataTestID} className={classname} size={size} />
);

export default Spin;
