import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { getRequestsSetters } from 'redux/ui';
import * as api from 'lib/api/users';
import * as actions from './actions';
import { User } from './types';

// login tests will fall if to leave "checkAuthentication" in thunks.ts file
export const checkAuthentication = createAsyncThunk(
  actions.checkAuthenticationAction,
  async (_: undefined, thunkAPI) => {
    const isSignedInWithSessionStorage = sessionStorage.getItem('amplify-signin-with-hostedUI');
    await Auth.configure({
      storage: isSignedInWithSessionStorage ? sessionStorage : localStorage,
    });
    const { setFailed, setPending, setSuccess } = getRequestsSetters('FETCH_USER_INFO');
    try {
      const user: CognitoUser & { attributes: User } = await Auth.currentAuthenticatedUser();
      const userInfo = user.attributes;
      await thunkAPI.dispatch(setPending());
      const { clientId, clientName, name, role } = await api.getUserInfo();
      thunkAPI.dispatch(setSuccess());
      if (clientId !== undefined && userInfo) {
        return {
          ...userInfo,
          clientId,
          name: name as string,
          clientName: clientName as string,
          email_verified: true,
          role: role as string,
        };
      }
      return { ...userInfo, clientName: clientName as string, role: role as string };
    } catch (e) {
      thunkAPI.dispatch(setFailed());
      return { name: null, email_verified: false, email: '', sub: '', clientId: null, clientName: null, role: null };
    }
  }
);

export interface LoginState {
  user: User | null;
}

export const initialState: LoginState = {
  user: null,
};

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkAuthentication.fulfilled, (state: LoginState, { payload }: PayloadAction<User>) => ({
      ...state,
      user: payload,
    }));
  },
});

export * from './selectors';

export default slice.reducer;
