import { RootState } from 'redux/store';
import { User, UserRole, UserStatus } from 'generatedSources';
import { UserProjects } from './types';

const users = (state: RootState) => state.users;

export const getUsers = (state: RootState): User[] | null => users(state).users;
export const getUserProjects = (state: RootState): UserProjects[] | null => users(state).userProjects;
export const getUsersStatusesAndItsCount = (state: RootState): Partial<Record<UserStatus, number>> | null =>
  users(state).statusesAndItsCount;
export const getUsersRolesAndItsCount = (state: RootState): Partial<Record<UserRole, number>> | null =>
  users(state).rolesAndItsCount;
export const getEditingUser = (state: RootState): User | null => users(state)?.editingUser;

export const getNumberOfUsers = (state: RootState): number | null => getUsers(state)?.length || null;

export const getUserByID =
  (state: RootState) =>
  (userID: number): User | null =>
    getUsers(state)?.find((user) => user.id === userID) || null;

export const getUserNamesByIDs =
  (state: RootState) =>
  (usersIDs: Array<number>): Array<string> => {
    const usersList = getUsers(state);
    if (usersList) {
      return usersList.filter((user) => usersIDs.includes(user.id)).map(({ username }) => username);
    }
    return [];
  };

export const getMultipleSelectedUsersRoles = (state: RootState) => (selectedUserIDs: number[]) => {
  const usersList = getUsers(state);
  if (usersList) {
    const selectedUsers = usersList?.filter((user) => selectedUserIDs.find((selectedID) => selectedID === user.id));
    const selectedUsersRoles = selectedUsers?.map((user) => user.role);
    if (new Set(selectedUsersRoles).size !== 1) return null;
    return selectedUsersRoles[0];
  }
  return null;
};
