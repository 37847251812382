import { createActionType } from 'lib/helpers/redux';

const listsPrefix = 'lists';

export const fetchDocumentsAction = createActionType(listsPrefix, 'fetchDocuments');
export const fetchListOfPredefinedClassesAction = createActionType(listsPrefix, 'fetchListOfPredefinedClasses');
export const deleteDocumentsAction = createActionType(listsPrefix, 'deleteDocuments');
export const excludeDocumentsListParamAction = createActionType(listsPrefix, 'excludeDocumentsListParam');
export const updateExistingDocumentInTheListAction = createActionType(listsPrefix, 'updateExistingDocumentInTheList');
export const updateDocumentsEntitiesAction = createActionType(listsPrefix, 'updateDocumentsEntities');
export const updateDocumentTypeAction = createActionType(listsPrefix, 'updateDocumentType');
export const excludeExcessDocumentsWithProcessingStatusAction = createActionType(
  listsPrefix,
  'excludeExcessDocumentsWithProcessingStatus'
);

export const setNewStatusesAndItsCountAction = createActionType(listsPrefix, 'setNewStatusesAndItsCount');
