import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { UserRole } from 'generatedSources';
import { User } from './types';

const login = (state: RootState) => state.login;

export const getUser = (state: RootState): User | null => login(state).user;
export const getUserEmail = (state: RootState) => getUser(state)?.email;
export const isAuthenticated = (state: RootState) => getUser(state)?.email_verified;
export const isAdmin = (state: RootState) => getUser(state)?.role === UserRole.Admin;
export const getClientId = (state: RootState): number | null => {
  const user = getUser(state);
  if (user && user.clientId !== undefined) return user.clientId;
  return null;
};
export const getClientName = (state: RootState): string | null => {
  const user = getUser(state);
  if (user && user.clientName !== undefined) return user.clientName;
  return null;
};

export const selectClientName = createSelector([getClientName], (clientName) => clientName);
