import React from 'react';

export const filtrationOption = <OptionType>(input: string, option: OptionType) => {
  const { key, value } = option as OptionType & { key: number | null; value: unknown };
  if (((key as number) || null) === null) return true;
  if (value) {
    return (value as string).toLowerCase().includes(input.toLowerCase());
  }
  return false;
};

export const stopPropagationHandler = (event: React.MouseEvent | React.SyntheticEvent) => event.stopPropagation();
