export const parseQuery = (queries: string) => {
  const query: Record<string, string> = {};
  const pairs = (queries[0] === '?' ? queries.substr(1) : queries).split('&');
  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

export const convertResponseToJSON = (response: Response) => response.json();

export const groupBy = <T, K>(arrayOfEntities: Array<T>, key: K): Record<string, Array<T>> => {
  return arrayOfEntities.reduce((prevValue, value) => {
    const newPrevValue: Record<string, Array<T>> = { ...prevValue };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (newPrevValue[value[key]] = newPrevValue[value[key]] || []).push(value);
    return newPrevValue;
  }, {});
};
