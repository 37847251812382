import { SearchMeta } from 'redux/document/review/types';
import { Point } from 'types/bbox';

export const DOCUMENT_MENU_WIDTH = 'documentMenuWidth';
export const DOCUMENT_VERIFY_MODAL_STATE = 'documentVerifyModalState';

export const GENERIC_KEYS = [
  'Series A-2',
  'Series A-3',
  'Series A-4',
  'Series A',
  'Series A-1',
  'Series B-1',
  'Series B',
  'Series B+',
];

export const CLASSIFICATION_KEYS = [
  'OBSERVATION 1',
  'OBSERVATION 2',
  'OBSERVATION 3',
  'OBSERVATION 4',
  'OBSERVATION 5',
  'OBSERVATION 6',
  'OBSERVATION 7',
  'OBSERVATION 8',
  'OBSERVATION 9',
  'OBSERVATION 10',
  'OBSERVATION 11',
  'OBSERVATION 12',
  'OBSERVATION 13',
  'OBSERVATION 14',
  'OBSERVATION 15',
  'OBSERVATION 16',
  'OBSERVATION 17',
  'OBSERVATION 18',
  'OBSERVATION 19',
  'OBSERVATION 20',
  'OBSERVATION 21',
  'OBSERVATION 22',
  'OBSERVATION 23',
  'OBSERVATION 24',
  'OBSERVATION 25',
];

export const defaultSearchMeta: SearchMeta = {
  searchString: '',
  selectedIndex: -1,
  matches: [],
};

export const getImageTranslate = (mouseX: number, mouseY: number, mouseDownPosition: Point): Point => ({
  x: mouseX - mouseDownPosition.x,
  y: mouseY - mouseDownPosition.y,
});

export const BBOX_TRANSLATE = { x: 0, y: 0 };

export const DESKTOP_SIZE = 1024;

export const MAX_MENU_WIDTH = 785;
export const MIN_MENU_WIDTH = 436;
