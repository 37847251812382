import { createActionType } from 'lib/helpers/redux';

export const usersPrefix = 'users';

export const fetchUsersAction = createActionType(usersPrefix, 'fetchUsers');

export const fetchMangeUsersAction = createActionType(usersPrefix, 'fetchManageUsers');

export const fetchUserProjectsAction = createActionType(usersPrefix, 'fetchUserProjects');

export const resetUserPasswordAction = createActionType(usersPrefix, 'resetUserPassword');

export const saveEditingUserAction = createActionType(usersPrefix, 'saveEditingUser');

export const setEditingUserByIDAction = createActionType(usersPrefix, 'setEditingUserByID');

export const undoUserEditingAction = createActionType(usersPrefix, 'undoUserEditing');

export const inviteUserAction = createActionType(usersPrefix, 'inviteUser');

export const updateUsersRolesAction = createActionType(usersPrefix, 'updateUsersRoles');

export const assignProjectsToUserAction = createActionType(usersPrefix, 'assignProjectsToUser');

export const archiveUsersToClientAction = createActionType(usersPrefix, 'archiveUsersToClient');
