import { useCallback } from 'react';
import { getRequestsSetters } from 'redux/ui';
import * as api from 'lib/api/documents';
import { getAllIdsOfDocumentsWithDefiniteStatus } from 'lib/api/documents';
import { exportFile } from 'lib/helpers/files';
import { useAppDispatch } from 'lib/hooks/useAppDispatch';
import { DocumentStatus } from 'generatedSources';
import { showErrorMessage, showSuccessMessage, showWarnMessage } from 'components/Common/Alert/Alerts';

export const useFileExport = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    async (documentIDs: number[]) => {
      const { setFailed, setPending, setSuccess } = getRequestsSetters('EXPORT_DOCUMENTS');
      try {
        dispatch(setPending());
        showWarnMessage('Data exporting will start in a few seconds...');

        const { link } = (await api.exportDocument(documentIDs)) as {
          link: string;
        };
        exportFile(link);
        dispatch(setSuccess());
        showSuccessMessage('Data was exported successfully!');
      } catch {
        dispatch(setFailed());
        showErrorMessage('Data exporting was failed.');
      }
    },
    [dispatch]
  );
};

interface UseProjectFilesExportProps {
  projectId: number | null | undefined;
}

export const useProjectFilesExport = ({ projectId }: UseProjectFilesExportProps) => {
  const exportFilesByIds = useFileExport();

  const exportFiles = useCallback(async () => {
    if (!projectId) {
      return null;
    }

    const verifiedDocumentsIds = await getAllIdsOfDocumentsWithDefiniteStatus(projectId, DocumentStatus.VERIFIED);

    return exportFilesByIds(verifiedDocumentsIds);
  }, [exportFilesByIds, projectId]);

  return exportFiles;
};
