import React from 'react';
import ReactDOM from 'react-dom';
import { useConfirm } from 'lib/hooks';
import Button from 'components/Common/Button/Button';
import Modal from 'components/Common/Modal/Modal';
import { stopPropagationHandler } from 'components/Common/helpers';

const rootElement = document.getElementById('root') as HTMLElement;

const UserConfirmation = () => {
  const { onConfirm, onCancel, confirmState } = useConfirm();
  const portalElement = document.createElement('div');

  React.useEffect(() => {
    rootElement.appendChild(portalElement);
    return () => {
      rootElement.removeChild(portalElement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = React.useMemo(
    () => (
      <>
        <Button
          dataTestID="document/delete-result-item-cancel-button"
          type="secondary"
          onClick={onCancel}
          onMouseDown={stopPropagationHandler}
        >
          {confirmState.cancelButtonText || 'Cancel'}
        </Button>
        <Button
          dataTestID="document/delete-result-item-submit-button"
          type="warning"
          onClick={onConfirm}
          onMouseDown={stopPropagationHandler}
        >
          {confirmState.confirmButtonText || 'OK'}
        </Button>
      </>
    ),
    [confirmState.cancelButtonText, confirmState.confirmButtonText, onCancel, onConfirm]
  );

  const component = React.useMemo(() => {
    if (!confirmState.visible) {
      return null;
    }
    return (
      <Modal
        modalDataTestID="document/confirm-modal"
        closable={false}
        maskClosable={false}
        visible={confirmState.visible}
        onHide={onCancel}
        size="medium"
        title={confirmState.textTitle}
        secondaryText={confirmState.text}
        actionsButtons={actions}
      />
    );
  }, [actions, confirmState.text, confirmState.textTitle, confirmState.visible, onCancel]);

  return ReactDOM.createPortal(component, portalElement);
};

export default UserConfirmation;
