import { createSelector } from 'reselect';
import { isNil } from 'lodash';
import {
  ActiveTabBBoxCoordinatesEntities,
  EditingFormMeta,
  EditingResultItemMeta,
  HoveredFormMeta,
  HoveredResultItemMeta,
  HoveredTableItemMeta,
  PageEntities,
  Pages,
  SearchMeta,
  SearchMatch,
  EditingResultKey,
  ItemWithPageNumber,
} from 'redux/document/review/types';
import {
  Cells,
  Document,
  DocumentType,
  DocumentStatus,
  Forms,
  Items,
  Page,
  TablesInterface,
  UseCaseSettings,
} from 'generatedSources/api';
import { RootState } from 'redux/store';
import { isFinished } from 'redux/ui';
import { compareTwoArrays } from 'lib/helpers/array';
import { setMapForPagesInfo } from './utils';

const review = (state: RootState) => state.document.review;

export const getEditingCell = (state: RootState): Cells | null => review(state).editingCell;

export const getSearchMeta = (state: RootState): SearchMeta => review(state).searchMeta;

export const getUseCaseSettings = (state: RootState): Partial<UseCaseSettings> | null => review(state).useCaseSettings;

export const getSearchedTextIndexesCount = (state: RootState): number => getSearchMeta(state).matches.length;

export const getGeneralSerialNumberOfSelectedText = (state: RootState) => getSearchMeta(state).selectedIndex + 1;

export const isTheFirstItemOfFullSearch = (state: RootState): boolean =>
  getGeneralSerialNumberOfSelectedText(state) === 1;

export const isTheLastItemOfFullSearch = (state: RootState) => {
  const activeTextSerialNumber = getGeneralSerialNumberOfSelectedText(state);
  const totalTextCount = getSearchedTextIndexesCount(state);
  return activeTextSerialNumber === totalTextCount;
};

export const getSelectedSearchItem = (state: RootState): SearchMatch | undefined => {
  const { matches, selectedIndex } = getSearchMeta(state);
  return matches[selectedIndex];
};

export const getActiveTabBBoxCoordinates = (state: RootState): Array<ActiveTabBBoxCoordinatesEntities> =>
  review(state).activeTabBBoxCoordinates;
export const getActiveTab = (state: RootState): PageEntities => review(state).activeDocumentTab;

export const getEditingResultItemMeta = (state: RootState): EditingResultItemMeta | null =>
  review(state).editingResultItemMeta;
export const getEditingResultsItem = (state: RootState): Items | null =>
  getEditingResultItemMeta(state)?.editingResultItems || null;

export const getEditingResultsKeys = (state: RootState): EditingResultKey[] | null =>
  getEditingResultItemMeta(state)?.editingResultsKey || null;

export const getEditingResultQuery = createSelector(getEditingResultsItem, (item) => (item ? item.query : null));
export const getEditingResultClassification = createSelector(getEditingResultsItem, (item) =>
  item && item.itemsInfo[0].labels ? item.itemsInfo[0].labels : []
);

export const getEditingResultsItemInfoID = createSelector(getEditingResultItemMeta, (editingResultItemMeta) => {
  if (editingResultItemMeta) {
    if (
      editingResultItemMeta.editingResultsItemInfoID !== null &&
      editingResultItemMeta.editingResultsItemInfoID !== undefined
    ) {
      return editingResultItemMeta.editingResultsItemInfoID;
    }
    if (editingResultItemMeta.editingResultItems && editingResultItemMeta.editingResultItems !== null) {
      return editingResultItemMeta.editingResultItems.itemsInfo[0].id;
    }
  }
  return null;
});

export const getEditingResultsItemIndex = createSelector(getEditingResultsItem, (editingResultsItem) => {
  if (editingResultsItem) {
    return editingResultsItem.index;
  }
  return null;
});

export const getEditingResultItemInfoTextValue = createSelector(
  getEditingResultsItem,
  getEditingResultsItemInfoID,
  (item, editingResultsItemInfoID) => {
    if (item) {
      return item.itemsInfo.find((itemInfo) => itemInfo.id === editingResultsItemInfoID)?.text || null;
    }
    return null;
  }
);

export const getEditingFormItemMeta = (state: RootState): EditingFormMeta | null => review(state).editingFormMeta;
export const getEditingFormKey = (state: RootState): string[] | null => getEditingFormItemMeta(state)?.key || null;
export const getEditingForm = (state: RootState): Forms | null => getEditingFormItemMeta(state)?.form || null;
export const getEditingFormIndex = (state: RootState) => {
  const index = getEditingForm(state)?.index;
  if (typeof index !== 'number') return null;
  return index;
};

export const getActiveEntityKey = (state: RootState) => review(state).activeEntityKey;

export const isDocumentEditedLocally = (state: RootState): boolean => review(state).isDocumentEditedLocally;

export const isShowVerifyDocumentModal = (state: RootState): boolean => review(state).isShowVerifyDocumentModal;

export const isShowEditingPopover = (state: RootState): boolean => review(state).isShowEditingPopover;

export const isSwitchingBetweenPages = (state: RootState): boolean => review(state).isSwitchingBetweenPages;

export const isImageWrapperSet = (state: RootState): boolean => review(state).isImageWrapperSet;

export const isDraggableMode = (state: RootState): boolean => review(state).isDraggableMode;

export const isAddingNewEntity = (state: RootState): boolean => review(state).isAddingNewEntity;

export const isDiscarded = (state: RootState): boolean => review(state).isDiscarded;

export const getCurrentPage = (state: RootState): number => review(state).currentPage;

export const getHoveredResultItemMeta = (state: RootState): HoveredResultItemMeta | null =>
  review(state).hoveredResultItemMeta;

export const getHoveredTableItemMeta = (state: RootState): HoveredTableItemMeta | null =>
  review(state).hoveredTableItemMeta;

export const getHoveredFormMeta = (state: RootState): HoveredFormMeta | null => review(state).hoveredFormItemMeta;

export const getTags = (state: RootState): string[] => review(state).tags || [];
export const getName = (state: RootState): string => review(state).name || '';
export const getFileUrl = (state: RootState): string | null => review(state).fileUrl;
export const getDocument = (state: RootState): Document | null => review(state).document;
export const isDocumentExists = (state: RootState): boolean => !!review(state).document;
export const isDocumentCorrected = (state: RootState): boolean => getDocument(state)?.corrected || false;
export const getNewEntitiesValidationError = (
  state: RootState
): Partial<Record<'key' | 'value' | 'labels' | 'observation', string>> | null =>
  review(state).newEntitiesValidationError;
export const getDocumentProjectID = (state: RootState): number | undefined => getDocument(state)?.projectId;
export const getDocumentID = (state: RootState): number | null => {
  const document = getDocument(state);
  if (document && document.id >= 0) return document.id;
  return null;
};

export const getErrorMessage = createSelector(
  getUseCaseSettings,
  getNewEntitiesValidationError,
  (useCaseSettings, newEntitiesValidationError) => {
    return useCaseSettings?.classificationEnabled
      ? newEntitiesValidationError?.labels
      : newEntitiesValidationError?.value;
  }
);

export const isImage = createSelector(getFileUrl, (fileUrl) => fileUrl?.search(/\.png\?|\.jpg\?|\.jpeg\?/) !== -1);

export const getStatus = (state: RootState): DocumentStatus | null => getDocument(state)?.status || null;
export const getDocumentType = (state: RootState): DocumentType | null => getDocument(state)?.documentClass || null;
export const isDocumentUnverified = (state: RootState): boolean => getStatus(state) === 'UNVERIFIED';
export const isDocumentVerified = (state: RootState): boolean => getStatus(state) === 'VERIFIED';
export const getAllDocumentPages = (state: RootState): Pages | null => getDocument(state)?.pages || null;

export const getDocumentPage = (state: RootState): Page | null => {
  const pages = getAllDocumentPages(state);
  if (pages !== null) {
    return pages[getCurrentPage(state)];
  }
  return null;
};

export const getDocumentPageTables = (state: RootState): TablesInterface[] | null => {
  const page = getDocumentPage(state);
  if (page !== null && page.tables) {
    return page.tables;
  }
  return null;
};

export const getDocumentPageItems = (state: RootState): Items[] | null => {
  const page = getDocumentPage(state);
  if (!isNil(page) && !isNil(page.items)) {
    return page.items;
  }
  return null;
};

export const getDocumentPageForms = (state: RootState): Forms[] | null => {
  const page = getDocumentPage(state);
  if (page !== null && page.forms) {
    return page.forms;
  }
  return null;
};

export const getTablesCellsSize = createSelector(getDocumentPageTables, (tables) => {
  if (tables !== null) {
    const entries = Object.entries(tables).map(([_i, table]) => {
      const maxColumnsValue = Object.values(table).map((cells) =>
        Object.values(cells as Record<number, Cells>).reduce(
          (acc: number, cell: Cells) => Math.max(acc, cell.columnId + 1),
          0
        )
      );
      return [table.tableIndex, Math.max(...maxColumnsValue)];
    });
    return Object.fromEntries(entries);
  }
  return 0;
});

export const getSpecificResultsItems =
  (state: RootState) =>
  (itemIndex: number): Items | null => {
    const items = getDocumentPageItems(state);
    return items?.find((i) => i.index === itemIndex) || null;
  };

export const getSpecificResultsItemsByEditingIndex = (state: RootState): ItemWithPageNumber | null => {
  const editingResultItemIndex = getEditingResultsItemIndex(state);
  const items = getDocumentPageItems(state);
  const currentPage = getCurrentPage(state);
  const item = items?.find((i) => i.index === editingResultItemIndex);
  if (item) return { ...item, pageNumber: currentPage };
  return null;
};

export const getSpecificFormsItem =
  (state: RootState) =>
  (itemIndex: number): Forms | null => {
    const forms = getDocumentPageForms(state);
    return forms?.find((form) => form.index === itemIndex) || null;
  };

export const getSpecificTableCell = (state: RootState): Cells | null => {
  const tables = getDocumentPageTables(state);
  const tableItemMeta = getHoveredTableItemMeta(state);
  if (tableItemMeta && tables) {
    const { hoveredTableColumnID, hoveredTableIndex, hoveredTableRowID } = tableItemMeta;
    const table = tables.find((t) => t.tableIndex === hoveredTableIndex);
    const cell = table?.cells.find((c) => c.rowId === hoveredTableRowID && c.columnId === hoveredTableColumnID);
    return cell || null;
  }
  return null;
};

export const isClassificationSettingEnabled = createSelector(
  getUseCaseSettings,
  (settings) => settings?.classificationEnabled
);

export const getDefiniteItemClassifications = (state: RootState): string[] | null => {
  const editingResultItemMeta = getEditingResultItemMeta(state);
  if (editingResultItemMeta) {
    const { editingResultItems, editingResultsItemInfoID } = editingResultItemMeta;
    const items = getSpecificResultsItems(state)(editingResultItems?.index as number);
    const itemInfo = items?.itemsInfo.find(({ id }) => id === editingResultsItemInfoID);
    if (itemInfo) return itemInfo.labels;
  }
  return null;
};

export const getTotalPages = createSelector(getDocument, (document) => {
  if (document) {
    return Object.keys(document.pages).length;
  }
  return 0;
});

export const getCurrentPageItems = createSelector(getAllDocumentPages, getCurrentPage, (pages, currentPage) => {
  if (!pages || isNil(pages[currentPage])) return null;
  return pages[currentPage].items;
});

export const getSpecificPageItems =
  (state: RootState) =>
  (pageNumber: number): Items[] | null => {
    const pages = getAllDocumentPages(state);
    if (!pages || !pages[pageNumber].items) return null;
    return pages[pageNumber].items as Items[];
  };

export const getAllPagesItems = createSelector(getAllDocumentPages, (pages) => {
  if (!pages) return null;
  return Object.keys(pages)
    .map((pageNumber) =>
      (pages[+pageNumber].items ?? []).map((item) => ({
        ...item,
        pageNumber: +pageNumber,
      }))
    )
    .flat();
});

export const getCurrentPageTables = createSelector(getAllDocumentPages, getCurrentPage, (pages, currentPage) => {
  if (!pages || isNil(pages[currentPage])) return null;
  return pages[currentPage].tables;
});

export const getCurrentPageForms = createSelector(getAllDocumentPages, getCurrentPage, (pages, currentPage) => {
  if (!pages) return null;
  return pages[currentPage].forms;
});

export const isAnyItemEditing = createSelector(
  getEditingCell,
  getEditingResultsItem,
  getEditingFormItemMeta,
  (editingCell, editingResultsItem, editingForm) => !!editingCell || !!editingResultsItem || !!editingForm
);

export const getArrayOfTablesAsDataForTable = createSelector(
  getAllDocumentPages,
  getCurrentPage,
  (pages, currentPage) => {
    if (pages) {
      return Object.values(setMapForPagesInfo(pages).tables[currentPage]);
    }
    return [];
  }
);

export const getEditingResultsItemsInfo = createSelector(getEditingResultsItem, (editingResultsItem) => {
  if (editingResultsItem) {
    return editingResultsItem.itemsInfo;
  }
  return null;
});

export const getHoveredResultItemIndex = createSelector(getHoveredResultItemMeta, (hoveredResultItemMeta) => {
  if (hoveredResultItemMeta) {
    if (hoveredResultItemMeta.hoveredResultItemIndex !== null) return hoveredResultItemMeta.hoveredResultItemIndex;
  }
  return null;
});

export const getHoveredResultItemInfoID = createSelector(getHoveredResultItemMeta, (hoveredItemMeta) => {
  if (hoveredItemMeta) {
    if (hoveredItemMeta.hoveredResultItemInfoID !== null) return hoveredItemMeta.hoveredResultItemInfoID;
  }
  return null;
});

export const getHoveredTableIndex = createSelector(getHoveredTableItemMeta, (hoveredTableItemMeta) => {
  if (hoveredTableItemMeta) {
    if (hoveredTableItemMeta.hoveredTableIndex !== null && hoveredTableItemMeta.hoveredTableIndex !== undefined)
      return hoveredTableItemMeta.hoveredTableIndex;
  }
  return null;
});

export const getHoveredTableRowID = createSelector(getHoveredTableItemMeta, (hoveredTableItemMeta) => {
  if (hoveredTableItemMeta) {
    if (hoveredTableItemMeta.hoveredTableRowID !== null && hoveredTableItemMeta.hoveredTableRowID !== undefined)
      return hoveredTableItemMeta.hoveredTableRowID;
  }
  return null;
});

export const getHoveredTableColumnID = createSelector(getHoveredTableItemMeta, (hoveredTableItemMeta) => {
  if (hoveredTableItemMeta) {
    if (hoveredTableItemMeta.hoveredTableColumnID !== null && hoveredTableItemMeta.hoveredTableColumnID !== undefined)
      return hoveredTableItemMeta.hoveredTableColumnID;
  }
  return null;
});

export const getHoveredResultItem = createSelector(
  getHoveredResultItemIndex,
  getHoveredResultItemInfoID,
  getCurrentPageItems,
  (hoveredItemIndex, hoveredItemInfoID, currentPageItems) => {
    const hoveredItem = currentPageItems?.find((item) => item.index === hoveredItemIndex);
    if (hoveredItem) return hoveredItem.itemsInfo.find((itemInfo) => itemInfo.id === hoveredItemInfoID);
    return null;
  }
);

export const getTablesCount = (state: RootState) => getCurrentPageTables(state)?.length;

export const getActiveCell = createSelector(
  getHoveredTableIndex,
  getHoveredTableRowID,
  getHoveredTableColumnID,
  getCurrentPageTables,
  getEditingCell,
  (activeTableIndex, activeRowID, activeColumnID, tables, editingCell) => {
    if (activeRowID !== null && activeTableIndex !== null && activeColumnID !== null && tables) {
      if (editingCell) {
        return {
          cell: editingCell,
          bbox: editingCell.bbox,
        };
      }
      const activeTable = tables.find((table) => table.tableIndex === activeTableIndex);

      if (activeTable) {
        const activeCell = activeTable.cells.find(
          (cell) => cell.columnId === activeColumnID && cell.rowId === activeRowID
        );
        return {
          cell: activeCell,
          bbox: activeCell?.bbox || [],
        };
      }
    }
    return null;
  }
);

export const isEditingResultItemChanged = createSelector(
  getEditingResultsItem,
  getSpecificResultsItemsByEditingIndex,
  (editedItem, originalItem) => {
    if (editedItem && originalItem) {
      const editedItemInfo = editedItem.itemsInfo[0];
      const originalItemInfo = originalItem.itemsInfo[0];
      const areQueriesEqual = editedItem.query === originalItem.query;
      const areLabelsEqual = compareTwoArrays(editedItemInfo.labels || [], originalItemInfo.labels || []);
      const areBBoxesEqual = compareTwoArrays(editedItemInfo.bbox || [], originalItemInfo.bbox || []);
      const areTextsEqual = editedItemInfo.text === originalItemInfo.text;
      return !areQueriesEqual || !areLabelsEqual || !areBBoxesEqual || !areTextsEqual;
    }
    return false;
  }
);

export const getActiveBBox = createSelector(
  getHoveredResultItem,
  getActiveCell,
  getHoveredFormMeta,
  (hoveredResultItem, activeCell, hoveredFormItem) => {
    if (hoveredResultItem) return hoveredResultItem.bbox;
    if (activeCell) return activeCell.bbox;
    if (hoveredFormItem) return hoveredFormItem.bbox;
    return null;
  }
);

export const isDocumentUrlFetched = createSelector(
  isFinished,
  getFileUrl,
  (isFetched, fileUrl) => isFetched('FETCH_URL_OF_DOCUMENT') && !!fileUrl
);
