import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentWithAssignedUser } from 'redux/document/review/types';
import { DocumentsProcessingStep } from 'types/documents';
import { DocumentListProcessedResponse, DocumentListState } from './types';
import { excludeDocumentsListParam, fetchDocuments, fetchListOfPredefinedClasses, updateDocument } from './thunks';

export const initialState: DocumentListState = {
  total: 0,
  documentCountWithProcessingOnes: 0,
  currentFoundedTotalDocuments: 0,
  statusesAndItsCount: {},
  currentStep: DocumentsProcessingStep.Upload,
  tagsAndItsCount: {},
  typesAndItsCount: {},
  entities: [],
  lastVisitedDocumentsListQueries: null,
  listOfPredefinedClasses: [],
};

const slice = createSlice({
  name: 'document/list',
  initialState,
  reducers: {
    setCurrentStep: (state: DocumentListState, { payload }: PayloadAction<DocumentsProcessingStep>) => {
      state.currentStep = payload;
    },
    setDocumentCountWithProcessingOnes: (state: DocumentListState, { payload }: PayloadAction<number>) => {
      state.documentCountWithProcessingOnes = payload;
    },
    setLastVisitedDocumentsListQueries: (state: DocumentListState, { payload }: PayloadAction<string | null>) => {
      state.lastVisitedDocumentsListQueries = payload;
    },
    setStatusesAndItsCount: (state: DocumentListState, { payload }: PayloadAction<Record<string, number>>) => {
      state.statusesAndItsCount = payload;
    },
    setTypesAndItsCount: (state: DocumentListState, { payload }: PayloadAction<Record<string, number>>) => {
      state.typesAndItsCount = payload;
    },
    resetDocumentsData: (state: DocumentListState) => ({
      ...initialState,
      lastVisitedDocumentsListQueries: state.lastVisitedDocumentsListQueries,
    }),
    setEntities: (state: DocumentListState, { payload }: PayloadAction<DocumentWithAssignedUser[]>) => {
      state.entities = payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setCurrentFoundedTotalDocuments: (state, action: PayloadAction<number>) => {
      state.currentFoundedTotalDocuments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchDocuments.fulfilled,
      (state: DocumentListState, { payload }: PayloadAction<DocumentListProcessedResponse | Error | null>) => {
        if (!payload || payload instanceof Error) return state;
        const {
          tagsAndItsCount,
          statusesAndItsCount,
          typesAndItsCount,
          total,
          currentFoundedTotalDocuments,
          entities,
          currentStep,
        } = payload;
        return {
          ...state,
          tagsAndItsCount,
          statusesAndItsCount,
          typesAndItsCount,
          total,
          currentFoundedTotalDocuments,
          entities,
          currentStep,
        };
      }
    );
    builder.addCase(excludeDocumentsListParam.fulfilled, (state, action) => {
      state.lastVisitedDocumentsListQueries = action.payload;
    });
    builder.addCase(updateDocument.fulfilled, (state, action) => {
      const index = state.entities.findIndex((item) => item.id === action.payload.id);

      const oldDocument = state.entities[index];

      const isDocumentTypeChanged = oldDocument && oldDocument.documentClass !== action.payload.documentClass;

      if (oldDocument && isDocumentTypeChanged) {
        const typesAndItsCount = { ...state.typesAndItsCount };
        typesAndItsCount[oldDocument.documentClass] -= 1;
        if (typesAndItsCount[oldDocument.documentClass] === 0) delete typesAndItsCount[oldDocument.documentClass];
        if (typesAndItsCount[action.payload.documentClass]) {
          typesAndItsCount[action.payload.documentClass] += 1;
        } else {
          typesAndItsCount[action.payload.documentClass] = 1;
        }
        state.typesAndItsCount = typesAndItsCount;
      }

      state.entities[index] = {
        ...state.entities[index],
        ...action.payload,
      };
    });
    builder
      .addCase(fetchListOfPredefinedClasses.fulfilled, (state, action) => {
        state.listOfPredefinedClasses = action.payload;
      })
      .addCase(fetchListOfPredefinedClasses.rejected, (state, { payload }) => {
        state.listOfPredefinedClasses = payload || [];
      });
  },
});

export const {
  setDocumentCountWithProcessingOnes,
  setCurrentStep,
  setLastVisitedDocumentsListQueries,
  resetDocumentsData,
  setStatusesAndItsCount,
  setTypesAndItsCount,
  setEntities,
  setTotal,
  setCurrentFoundedTotalDocuments,
} = slice.actions;

export * from './selectors';

export default slice.reducer;
