import * as queryString from 'query-string';
import { Auth } from 'aws-amplify';

const baseAPI = async (url: string, option?: RequestInit | null, params = {}, signal?: AbortSignal) => {
  const { headers, ...restOptionParams } = option || {};
  const apiPath = process.env.REACT_APP_API_URL;
  const user = await Auth.currentAuthenticatedUser();
  const JwtToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
  const modifiedURL = `${apiPath + url}?${queryString.stringify(params)}`;
  const response = await fetch(modifiedURL, {
    mode: 'cors',
    signal,
    credentials: 'include',
    headers: {
      ...headers,
      Authorization: JwtToken,
    },
    ...restOptionParams,
  });
  if (response.ok) return response;
  // eslint-disable-next-line no-restricted-globals
  if (response.status === 401) location.replace('/login');
  // eslint-disable-next-line no-restricted-globals
  if (response.status === 403) location.replace('/forbidden');
  const res = await response.json();
  throw new Error(res.message, { cause: { ...res, status: response.status.toString() } });
};

export default baseAPI;
