import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Cells, Document, DocumentType, Items, UseCaseSettings } from 'generatedSources/api';
import {
  ActiveTabBBoxCoordinatesEntities,
  DocumentReviewState,
  EditingFormMeta,
  EditingResultKey,
  HoveredFormMeta,
  HoveredResultItemMeta,
  HoveredTableItemMeta,
  PageEntities,
  SearchMeta,
} from 'redux/document/review/types';
import { defaultSearchMeta } from 'components/Document/Common/constants';

export const initialState: DocumentReviewState = {
  document: null,
  fileUrl: null,
  searchMeta: defaultSearchMeta,
  name: null,
  tags: null,
  activeTabBBoxCoordinates: [],
  currentPage: 1,
  editingResultItemMeta: null,
  editingCell: null,
  editingFormMeta: null,
  hoveredResultItemMeta: null,
  hoveredTableItemMeta: null,
  hoveredFormItemMeta: null,
  activeEntityKey: null,
  activeDocumentTab: 'Results',
  newEntitiesValidationError: null,
  useCaseSettings: null,
  isDocumentEditedLocally: false,
  isShowVerifyDocumentModal: true,
  isShowEditingPopover: false,
  isSwitchingBetweenPages: true,
  isImageWrapperSet: false,
  isDraggableMode: true,
  isAddingNewEntity: false,
  isDiscarded: false,
};

const slice = createSlice({
  name: 'document/review',
  initialState,
  reducers: {
    setActiveEntityKey: (state: DocumentReviewState, { payload }: PayloadAction<string | null>) => ({
      ...state,
      activeEntityKey: payload,
    }),
    setActiveTabBBoxCoordinates: (
      state: DocumentReviewState,
      { payload }: PayloadAction<Array<ActiveTabBBoxCoordinatesEntities>>
    ) => ({
      ...state,
      activeTabBBoxCoordinates: payload,
    }),
    setEditingResultsItems: (state: DocumentReviewState, { payload }: PayloadAction<Items | null>) => ({
      ...state,
      editingResultItemMeta: { ...state.editingResultItemMeta, editingResultItems: payload },
    }),
    setEditingResultsItemInfoID: (state: DocumentReviewState, { payload }: PayloadAction<number | null>) => ({
      ...state,
      editingResultItemMeta: { ...state.editingResultItemMeta, editingResultsItemInfoID: payload },
    }),
    setEditingResultsKey: (state: DocumentReviewState, { payload }: PayloadAction<EditingResultKey[] | null>) => ({
      ...state,
      editingResultItemMeta: { ...state.editingResultItemMeta, editingResultsKey: payload },
    }),
    setEditingCell: (state: DocumentReviewState, { payload }: PayloadAction<Cells | null>) => ({
      ...state,
      editingCell: payload,
    }),
    setHoveredTableItemMeta: (state: DocumentReviewState, { payload }: PayloadAction<HoveredTableItemMeta | null>) => ({
      ...state,
      hoveredTableItemMeta: payload,
    }),
    setEditingFormMeta: (state: DocumentReviewState, { payload }: PayloadAction<EditingFormMeta | null>) => ({
      ...state,
      editingFormMeta: payload,
    }),
    setHoveredFormItemMeta: (state: DocumentReviewState, { payload }: PayloadAction<HoveredFormMeta | null>) => ({
      ...state,
      hoveredFormItemMeta: payload,
    }),
    setCurrentPage: (state: DocumentReviewState, { payload }: PayloadAction<number>) => ({
      ...state,
      currentPage: payload,
    }),
    setHoveredResultItemMeta: (
      state: DocumentReviewState,
      { payload }: PayloadAction<HoveredResultItemMeta | null>
    ) => ({
      ...state,
      hoveredResultItemMeta: payload,
    }),
    setTags: (state: DocumentReviewState, { payload }: PayloadAction<string[] | null>) => ({
      ...state,
      tags: payload,
    }),
    setName: (state: DocumentReviewState, { payload }: PayloadAction<string | null>) => ({
      ...state,
      name: payload,
    }),
    setSearchMeta: (state: DocumentReviewState, { payload }: PayloadAction<SearchMeta>) => ({
      ...state,
      searchMeta: payload,
    }),
    setSearchSelectedIndex: (state: DocumentReviewState, { payload }: PayloadAction<number>) => ({
      ...state,
      searchMeta: {
        ...state.searchMeta,
        selectedIndex: payload,
      },
    }),
    setDocumentType: (state: DocumentReviewState, { payload }: PayloadAction<DocumentType | null>) =>
      payload
        ? {
            ...state,
            document: { ...(state.document as Document), documentClass: payload },
          }
        : {
            ...state,
          },
    setFileUrl: (state: DocumentReviewState, { payload }: PayloadAction<string | null>) => ({
      ...state,
      fileUrl: payload,
    }),
    setDocument: (state: DocumentReviewState, { payload }: PayloadAction<Document | null>) => ({
      ...state,
      document: payload,
    }),
    setModifiedDocument: (state: DocumentReviewState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      document: { ...(state.document as Document), modified: payload },
    }),
    setIsDocumentEditedLocally: (state: DocumentReviewState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDocumentEditedLocally: payload,
    }),
    setIsShowVerifyDocumentModal: (state: DocumentReviewState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isShowVerifyDocumentModal: payload,
    }),
    setIsDraggableMode: (state: DocumentReviewState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDraggableMode: payload,
    }),
    setIsShowEditingPopover: (state: DocumentReviewState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isShowEditingPopover: payload,
    }),
    setIsSwitchingBetweenPages: (state: DocumentReviewState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isSwitchingBetweenPages: payload,
    }),
    setIsImageWrapperSet: (state: DocumentReviewState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isImageWrapperSet: payload,
    }),
    setIsAddingNewEntity: (state: DocumentReviewState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isAddingNewEntity: payload,
    }),
    setIsDiscarded: (state: DocumentReviewState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDiscarded: payload,
    }),
    setActiveDocumentTab: (state: DocumentReviewState, { payload }: PayloadAction<PageEntities>) => ({
      ...state,
      activeDocumentTab: payload,
    }),
    setNewEntitiesValidationError: (
      state: DocumentReviewState,
      { payload }: PayloadAction<Partial<Record<'key' | 'value', string>> | null>
    ) => ({
      ...state,
      newEntitiesValidationError: payload,
    }),
    setProjectUseCaseSettings: (state: DocumentReviewState, { payload }: PayloadAction<UseCaseSettings>) => ({
      ...state,
      useCaseSettings: payload,
    }),
    resetDocumentData: () => ({
      ...initialState,
    }),
  },
});

export const {
  setActiveEntityKey,
  setHoveredResultItemMeta,
  setActiveTabBBoxCoordinates,
  setHoveredTableItemMeta,
  setEditingCell,
  setCurrentPage,
  setEditingResultsItems,
  setEditingResultsItemInfoID,
  setEditingResultsKey,
  setEditingFormMeta,
  setHoveredFormItemMeta,
  setTags,
  setName,
  setSearchMeta,
  setSearchSelectedIndex,
  setDocumentType,
  setFileUrl,
  setDocument,
  setModifiedDocument,
  setActiveDocumentTab,
  setIsDocumentEditedLocally,
  setIsShowVerifyDocumentModal,
  setIsDraggableMode,
  setIsShowEditingPopover,
  setIsSwitchingBetweenPages,
  setIsImageWrapperSet,
  setIsAddingNewEntity,
  setIsDiscarded,
  setNewEntitiesValidationError,
  setProjectUseCaseSettings,
  resetDocumentData,
} = slice.actions;

export * from './selectors';

export default slice.reducer;
