import { isEqual } from 'lodash';

export const areAllEqual = <T>(arr: Array<T>): boolean => arr.every((v) => v === arr[0]);

export const sortArrayAlphabetically = (array: Array<string>) => {
  return array.sort((a, b) => {
    const stringA = a.toLowerCase();
    const stringB = b.toLowerCase();
    if (stringA < stringB) return -1;
    if (stringA > stringB) return 1;
    return 0;
  });
};

export const compareTwoArrays = <T>(arrayA: Array<T>, arrayB: Array<T>) => isEqual(arrayA, arrayB);
