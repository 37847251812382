import * as Yup from 'yup';

export interface SignInFormData {
  username: string;
  password: string;
  keepLoggedIn: boolean;
}

export interface SignUpFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface NewPasswordData {
  code: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const AMPLIFY_USERNAME_KEY = 'ReactAmplify.Username';
export const USER_PASSWORD_KEY = 'UserPassword';
export const SPECIFIC_REDIRECT_KEY = 'SpecificRedirect';

export const LOGIN_FORM_INITIAL_DATA = { username: '', password: '', keepLoggedIn: true };
export const SIGN_UP_FORM_INITIAL_DATA = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '' };
export const SET_NEW_PASSWORD_FORM_INITIAL_DATA = { code: '', newPassword: '', confirmNewPassword: '' };

export const SignupSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name is required'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name is required'),
  email: Yup.string().strict(false).trim().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password'),
});

export const SendVerifyCodeFormSchema = Yup.object().shape({
  email: Yup.string().strict(false).trim().email('Invalid email').required('Email is required'),
});

export const setNewPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required('Password is required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password'),
  code: Yup.string().required('Verification code is required'),
});

export const LoginSchema = Yup.object().shape({
  username: Yup.string().strict(false).trim().email('Wrong email format').required('Username is required'),
  password: Yup.string().min(2, 'Too Short!').required('Password is required'),
});

export const ConfirmEmailSchema = Yup.object().shape({
  verifyCode: Yup.string().required('Verify code is required'),
});

export const setUserPassword = (password: string) => localStorage.setItem(USER_PASSWORD_KEY, password);
export const getUserPassword = () => localStorage.getItem(USER_PASSWORD_KEY);
export const resetUserPassword = () => localStorage.removeItem(USER_PASSWORD_KEY);

export const setSpecificRedirect = (specificRedirect: string) =>
  localStorage.setItem(SPECIFIC_REDIRECT_KEY, specificRedirect);
export const getSpecificRedirect = () => localStorage.getItem(SPECIFIC_REDIRECT_KEY);
export const resetSpecificRedirect = () => localStorage.removeItem(SPECIFIC_REDIRECT_KEY);

export const setUsernameKey = (email: string) => localStorage.setItem(AMPLIFY_USERNAME_KEY, email);
export const getUsername = () => localStorage.getItem(AMPLIFY_USERNAME_KEY);
export const resetUsername = () => localStorage.removeItem(AMPLIFY_USERNAME_KEY);
