import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'generatedSources/api';
import { UserRole, UserStatus } from 'generatedSources';
import { fetchUserProjects, setEditingUserByID } from './thunks';
import { UsersState } from './types';

export const initialState: UsersState = {
  users: null,
  statusesAndItsCount: null,
  rolesAndItsCount: null,
  editingUser: null,
  userProjects: null,
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state: UsersState, { payload }: PayloadAction<User[] | null>) => ({
      ...state,
      users: payload,
    }),
    setStatusesAndItsCount: (
      state: UsersState,
      { payload }: PayloadAction<Partial<Record<UserStatus, number>> | null>
    ) => ({
      ...state,
      statusesAndItsCount: payload,
    }),
    setRolesAndItsCount: (state: UsersState, { payload }: PayloadAction<Partial<Record<UserRole, number>> | null>) => ({
      ...state,
      rolesAndItsCount: payload,
    }),
    setEditingUser: (state: UsersState, { payload }: PayloadAction<User | null>) => ({
      ...state,
      editingUser: payload,
    }),
    resetUsersData: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(setEditingUserByID.fulfilled, (state: UsersState, action) => {
      state.editingUser = action.payload;
    });

    builder.addCase(fetchUserProjects.fulfilled, (state: UsersState, action) => {
      state.userProjects = action.payload;
    });
  },
});

export const { setUsers, setStatusesAndItsCount, setRolesAndItsCount, setEditingUser, resetUsersData } = slice.actions;

export * from './selectors';

export default slice.reducer;
