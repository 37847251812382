import { BBox, BBoxRelative } from 'types/bbox';

export const relativeToAbsoluteBBox = (bbox: number[], canvasWidth: number, canvasHeight: number): BBox => {
  if (bbox && bbox.length)
    return {
      left: bbox[0] * canvasWidth,
      top: bbox[1] * canvasHeight,
      bottom: bbox[5] * canvasHeight,
      right: bbox[2] * canvasWidth,
    };

  return {
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  };
};

export const absoluteToRelativeBBox = (bbox: BBox, canvasWidth: number, canvasHeight: number): BBoxRelative => {
  const top = bbox.top / canvasHeight;
  const bottom = bbox.bottom / canvasHeight;
  const left = bbox.left / canvasWidth;
  const right = bbox.right / canvasWidth;
  return [left, top, right, top, right, bottom, left, bottom];
};
