import { DocumentStatus, DocumentType } from 'generatedSources';
import { DocumentWithAssignedUser } from 'redux/document/review/types';

export const getNewSelectedEntities = (item: string, items: string[] | null) => {
  if (items === null) return [item];
  const itemIndex = items.findIndex((v) => v === item);
  if (itemIndex === -1) return [...items, item];
  const newItems = [...items];
  newItems.splice(itemIndex, 1);
  return newItems;
};

const getDocumentsCountByStatus = (
  documentsList: DocumentWithAssignedUser[],
  status: DocumentStatus
): Record<string, number> => ({
  [status]: documentsList.filter((document) => document.status === status).length,
});

export const getAllDocumentsCountByStatus = (statusesAndDocumentsCount: Record<DocumentStatus, number>): number =>
  Object.values(statusesAndDocumentsCount).reduce((acc, current) => acc + current, 0);

const getCountOfAllJustProcessedDocumentsByItsStatuses = (
  documentsList: DocumentWithAssignedUser[]
): Record<DocumentStatus, number> => {
  const documentsStatuses = Object.keys(DocumentStatus) as Array<DocumentStatus>;
  const documentsCountByStatuses: Array<Record<DocumentStatus, number>> = documentsStatuses.map((status) =>
    getDocumentsCountByStatus(documentsList, status)
  );

  let countOfAllDocumentsByItsStatuses = {};
  documentsStatuses.forEach((status) => {
    const indexOfExistingStatuses = documentsCountByStatuses.findIndex((documents) => documents[status]);
    if (indexOfExistingStatuses !== -1) {
      countOfAllDocumentsByItsStatuses = {
        ...countOfAllDocumentsByItsStatuses,
        [status]: documentsCountByStatuses[indexOfExistingStatuses][status],
      };
    }
  });

  return countOfAllDocumentsByItsStatuses as Record<DocumentStatus, number>;
};

export const takeIntoAccountOldAndNewStatuses = (
  justProcessedDocuments: DocumentWithAssignedUser[],
  statusesAndItsCount: Partial<Record<DocumentStatus, number>>
) => {
  const countOfAllJustProcessedDocumentsByItsStatuses =
    getCountOfAllJustProcessedDocumentsByItsStatuses(justProcessedDocuments);
  const justProcessedDocumentsStatusesKeys = Object.keys(countOfAllJustProcessedDocumentsByItsStatuses);
  let newStatusesAndItsCount = statusesAndItsCount;
  justProcessedDocumentsStatusesKeys.forEach((statusKey) => {
    newStatusesAndItsCount = {
      ...newStatusesAndItsCount,
      [statusKey]:
        (statusesAndItsCount[statusKey as DocumentStatus] || 0) +
        countOfAllJustProcessedDocumentsByItsStatuses[statusKey as DocumentStatus],
    };
  });
  const numberOfDocumentsWhichLeftProcessingStatus =
    justProcessedDocuments.length - (countOfAllJustProcessedDocumentsByItsStatuses.PROCESSING || 0);
  const newCountOfProcessingDocuments =
    (statusesAndItsCount[DocumentStatus.PROCESSING] || 0) +
    (countOfAllJustProcessedDocumentsByItsStatuses.PROCESSING || 0) -
    numberOfDocumentsWhichLeftProcessingStatus;
  if (newCountOfProcessingDocuments) {
    newStatusesAndItsCount = {
      ...newStatusesAndItsCount,
      [DocumentStatus.PROCESSING]: newCountOfProcessingDocuments,
    };
  } else {
    delete newStatusesAndItsCount.PROCESSING;
  }
  return newStatusesAndItsCount;
};

export const filterDocumentsByQueryStatuses = (
  entities: DocumentWithAssignedUser[],
  queryStatuses: DocumentStatus | null
) => entities.filter((entity) => queryStatuses === entity.status);

export const getIsDisabled = (status: DocumentStatus) => ['PROCESSING', 'ERROR', 'UNPROCESSED'].includes(status);

export const takeIntoAccountOldAndNewTypes = (
  justProcessedDocuments: DocumentWithAssignedUser[],
  typesAndItsCount: Partial<Record<DocumentType, number>>
) => {
  const typesOfProcessedDocuments = Array.from(
    new Set(justProcessedDocuments.map(({ documentClass }) => documentClass))
  );

  let newTypesAndItsCount = typesAndItsCount;

  typesOfProcessedDocuments.forEach((typeKey) => {
    const documentsCountOfParticularType =
      (typesAndItsCount[typeKey] || 0) +
      justProcessedDocuments.filter(({ documentClass }) => documentClass === typeKey).length;

    newTypesAndItsCount = {
      ...newTypesAndItsCount,
      [typeKey]: documentsCountOfParticularType,
    };
  });

  return newTypesAndItsCount;
};
