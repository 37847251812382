import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { CheckOutlined, ExclamationOutlined } from '@ant-design/icons';
import classnames from 'classnames/bind';
import { Alert as AlertI } from 'redux/ui/types';
import { ReactComponent as CrossIcon } from 'assets/images/svg/close.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/svg/error.svg';
import classes from './Alerts.module.scss';

const CLOSE_TIMEOUT_MS = 5000;

const alertsData = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  add: (_config: AlertI) => {},
};

export const showSuccessMessage = (message: string): void => {
  alertsData.add({
    message,
    type: 'success',
  });
};

export const showErrorMessage = (message: string): void => {
  alertsData.add({
    message,
    type: 'error',
  });
};

export const showWarnMessage = (message: string): void => {
  alertsData.add({
    message,
    type: 'warning',
  });
};

const cx = classnames.bind(classes);

const getAlertIcon = (type: string) => {
  if (type === 'success') return <CheckOutlined />;
  if (type === 'error') return <ErrorIcon />;
  return <ExclamationOutlined />;
};

export const Alerts = () => {
  const [alerts, setAlerts] = useState<AlertI[]>([]);

  const closeAlert = (text: React.ReactNode) => {
    setAlerts((prevState) => {
      if (prevState.length <= 1) return [];
      return prevState.filter((alert) => alert.message !== text);
    });
  };

  useEffect(() => {
    alertsData.add = (config: AlertI) => {
      setAlerts((prevState) => {
        return prevState.filter((alert) => alert.message === config.message).length
          ? [...prevState]
          : [...prevState, config];
      });
      if (config.type !== 'error') {
        setTimeout(() => {
          closeAlert(config.message);
        }, CLOSE_TIMEOUT_MS);
      }
    };
  }, []);

  if (alerts.length === 0) return null;

  return (
    <div className={cx('Alerts')}>
      {alerts.map((alert) => (
        <div key={alert.message} data-testid="Alert" className={cx('Alert', alert.type)}>
          <div className={cx('AlertPrefixIcon')}>{getAlertIcon(alert.type)}</div>
          <div className={cx('AlertMessage')}>{alert.message}</div>
          {alert.type === 'error' && (
            <Button className={cx('AlertCross')} icon={<CrossIcon />} onClick={() => closeAlert(alert.message)} />
          )}
        </div>
      ))}
    </div>
  );
};

export default Alerts;
