import React, { FC, useEffect } from 'react';
import { useAppDispatch } from 'lib/hooks/useAppDispatch';
import { withConfirmProvider } from 'lib/hooks/useCofirmContext';
import { checkAuthentication } from 'redux/login';
import classnames from 'classnames/bind';
import Spin from 'components/Common/Spin/Spin';
import mainLayoutClasses from 'components/Common/Layout/MainLayout.module.scss';
import Alerts from 'components/Common/Alert/Alerts';
import UserConfirmation from 'components/Document/Common/DocumentModals/UserConfirmation';

import classes from './App.module.scss';
import ApplicationRoutes from './Routes/ApplicationRoutes';

export interface AppProps {
  isAuth: boolean | undefined;
  isAdmin: boolean;
}

const cx = classnames.bind(classes);
const mainLayoutClassesBind = classnames.bind(mainLayoutClasses);

const App: FC<AppProps> = ({ isAuth, isAdmin }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(checkAuthentication());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSpinner = React.useMemo(() => <Spin classname={mainLayoutClassesBind('LayoutSpin')} size="large" />, []);
  const renderSpinnerSuspense = React.useMemo(
    () => <Spin classname={mainLayoutClassesBind('LayoutSpin')} size="large" />,
    []
  );

  if (isAuth === undefined) return renderSpinner;

  return (
    <React.Suspense fallback={renderSpinnerSuspense}>
      <div className={cx('App')}>
        <UserConfirmation />
        <Alerts />
        <div className={cx('Body')}>
          <ApplicationRoutes isAuth={isAuth} isAdmin={isAdmin} />
        </div>
      </div>
    </React.Suspense>
  );
};

export default withConfirmProvider(App);
