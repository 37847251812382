import { createActionType } from 'lib/helpers/redux';

export const loginPrefix = 'login';

export const checkAuthenticationAction = createActionType(loginPrefix, 'checkAuthentication');

export const signInAction = createActionType(loginPrefix, 'signIn');

export const signOutAction = createActionType(loginPrefix, 'signOut');

export const resendSignUpAction = createActionType(loginPrefix, 'resendSignUp');

export const signUpAction = createActionType(loginPrefix, 'signUp');

export const sendVerificationAction = createActionType(loginPrefix, 'sendVerification');

export const updatePasswordAction = createActionType(loginPrefix, 'updatePassword');

export const confirmSignUpAction = createActionType(loginPrefix, 'confirmSignUp');
