import { UserInfo } from 'redux/login/types';
import { User } from 'generatedSources';
import { AssignAndUnAssignProjectsParams, UpdateUsersRoles } from 'types/users';
import { FetchUsersProps, InviteUserProps, UsersListQueries } from 'redux/users/types';
import { convertResponseToJSON } from './helpers';
import baseAPI from './baseAPI';

export const getUserInfo = async (): Promise<UserInfo> => {
  return convertResponseToJSON(await baseAPI(`/admin/user/info`, { method: 'GET' }));
};

export const getUsers = async (props: FetchUsersProps): Promise<User[]> => {
  const { queries, clientID } = props;
  const { userFirstName, userLastName, userEmail } = queries;
  return convertResponseToJSON(
    await baseAPI(`/user/client/${clientID}/projects`, { method: 'GET' }, { userFirstName, userLastName, userEmail })
  );
};

export const inviteUser = async (props: InviteUserProps) => {
  const { firstName, lastName, values } = props;
  const { email, groupName } = values;
  await baseAPI('/admin/user/invite', {
    method: 'POST',
    body: JSON.stringify({ email, userGroup: groupName, firstName, lastName }),
  });
};

export const updateUser = async (props: User): Promise<User[]> => {
  const { username, firstName, lastName, role } = props;
  return convertResponseToJSON(
    await baseAPI(`/user`, {
      method: 'PUT',
      body: JSON.stringify({
        username,
        firstName,
        lastName,
        role,
      }),
    })
  );
};

export const updateRolesOfUsers = async (props: UpdateUsersRoles): Promise<Array<string>> => {
  return convertResponseToJSON(await baseAPI(`/user/groups`, { method: 'PUT', body: JSON.stringify(props) }));
};

export const resetUserPassword = async (username: string) => {
  await baseAPI(`/user/password/reset`, { method: 'POST', body: JSON.stringify({ username }) });
};

export const getUserProjects = async (userId: number) => {
  const response = await baseAPI('/admin/projects', { method: 'GET' }, { userId });
  return response.json();
};

export const assignUserToProject = async (userId: number, props: AssignAndUnAssignProjectsParams) => {
  await baseAPI(`/user/${userId}/projects`, {
    method: 'POST',
    body: JSON.stringify(props),
  });
};

export const archiveUser = async (ids: number[]) => {
  await baseAPI(`/user/archive`, {
    method: 'POST',
    body: JSON.stringify({ ids }),
  });
};

export const getMangeUsers = async (
  queries: UsersListQueries,
  clientId: number,
  projectId: number
): Promise<User[]> => {
  const { userFirstName, userLastName, userEmail } = queries;
  return convertResponseToJSON(
    await baseAPI(
      `/clients/${clientId}/projects/${projectId}/users`,
      { method: 'GET' },
      { userFirstName, userLastName, userEmail }
    )
  );
};

export const assignManageUsers = async (clientId: number, projectId: number, props: number[]) => {
  await baseAPI(`/clients/${clientId}/projects/${projectId}/users`, {
    method: 'POST',
    body: JSON.stringify({ ids: props }),
  });
};
