const getBlobObjectUrl = async (url: string) => {
  const response = await fetch(url, {
    mode: 'cors',
    method: 'GET',
  });
  const data = await response.blob();
  return window.URL.createObjectURL(new Blob([data]));
};

export default getBlobObjectUrl;
