import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project } from 'generatedSources';
import { Model, ProjectsState, SingleUseCaseResponse, UseCase } from './types';
import * as thunks from './thunks';

export const initialState: ProjectsState = {
  projects: [],
  models: [],
  editingProject: null,
  currentProject: null,
  useCaseList: [],
  currentProjectUseCase: null,
};

const slice = createSlice({
  name: 'projects',
  initialState,
  // todo: remove reducers
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.fetchProjects.fulfilled, (state: ProjectsState, { payload }: PayloadAction<Array<Project>>) => ({
        ...state,
        projects: payload,
      }))
      .addCase(thunks.fetchModels.fulfilled, (state: ProjectsState, { payload }: PayloadAction<Array<Model>>) => ({
        ...state,
        models: payload,
      }))
      .addCase(
        thunks.fetchUseCaseList.fulfilled,
        (state: ProjectsState, { payload }: PayloadAction<Array<UseCase>>) => ({
          ...state,
          useCaseList: payload,
        })
      )
      .addCase(
        thunks.fetchProjectUseCase.fulfilled,
        (state: ProjectsState, { payload }: PayloadAction<SingleUseCaseResponse>) => ({
          ...state,
          currentProjectUseCase: payload,
        })
      )
      .addCase(thunks.fetchProjectUseCase.rejected, (state: ProjectsState, { payload }) => ({
        ...state,
        currentProjectUseCase: payload as null,
      }))
      .addCase(
        thunks.changeProjectModel.fulfilled,
        (state: ProjectsState, { payload }: PayloadAction<Project | null>) => ({
          ...state,
          editingProject: payload,
        })
      )
      .addCase(
        thunks.changeEditingProject.fulfilled,
        (state: ProjectsState, { payload }: PayloadAction<Project | null>) => ({
          ...state,
          editingProject: payload,
        })
      )
      .addCase(
        thunks.changeCurrentProject.fulfilled,
        (state: ProjectsState, { payload }: PayloadAction<Project | null>) => ({
          ...state,
          currentProject: payload,
        })
      )
      .addCase(thunks.saveEditingProject.fulfilled, (state: ProjectsState, { payload }) => {
        return {
          ...state,
          projects: payload?.newProjects || state.projects,
          currentProject: payload?.currentProject || null,
          editingProject: null,
        };
      });
  },
});

export const { resetStore } = slice.actions;

export * from './selectors';

export default slice.reducer;
