import isDev from 'lib/isDev';
import getBlobObjectUrl from 'lib/getBlobObjectUrl';
import { DocumentsResponse, DocumentStatus, DocumentUpdate } from 'generatedSources';
import { Document } from 'generatedSources/api';
import { ModelResultFeedback } from 'redux/document/review/types';
import { DocumentFetchQueries } from 'redux/document/list/types';
import baseAPI from './baseAPI';
import { convertResponseToJSON } from './helpers';

const { REACT_APP_S3_URL, REACT_APP_S3_PROXY_PATH } = process.env;

export const fetchDocuments = async (
  queries: DocumentFetchQueries,
  signal?: AbortSignal
): Promise<DocumentsResponse> => {
  let limit = 100;
  if (queries.page) limit = 10;
  return convertResponseToJSON(
    await baseAPI(
      '/document',
      { method: 'GET' },
      {
        ...queries,
        limit,
      },
      signal
    )
  );
};

export const deleteDocument = (ids: number[]) => baseAPI(`/document`, { method: 'DELETE' }, { ids });
export const exportDocument = (documentsIDs: number[]) => {
  return baseAPI(`/document/export`, { method: 'POST', body: JSON.stringify({ ids: documentsIDs }) }).then((response) =>
    response.json()
  );
};

export const fetchUploadDocumentUrl = async (
  documentName: string,
  userProjectName: string | null,
  clientName: string | null
): Promise<string> => {
  let company = 'Demo Company';
  let project = 'Demo Project';
  if (userProjectName) project = userProjectName;
  if (clientName) company = clientName;
  const { url } = await convertResponseToJSON(
    await baseAPI(`/document/${company}/${project}/${documentName}/upload`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/octet-stream' },
    })
  );
  return isDev() ? url.replace(REACT_APP_S3_URL, REACT_APP_S3_PROXY_PATH) : url;
};

export const fetchSingleDocumentUrl = async (documentName: string): Promise<string> => {
  const path = documentName?.replace(/raw/, 'document');
  try {
    const response = await baseAPI(`/${path}/download`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/octet-stream' },
    }).then((resp) => {
      return resp.json();
    });
    const modifiedUrl = isDev() ? response.url.replace(REACT_APP_S3_URL || '', REACT_APP_S3_PROXY_PATH) : response.url;
    if (modifiedUrl?.search(/png|jpg|jpeg/) === -1) {
      return await getBlobObjectUrl(modifiedUrl);
    }
    return modifiedUrl;
  } catch (e) {
    const error = e as Error;
    throw Error(error.message);
  }
};

export const fetchMultipleDocumentsUrl = async (documentsIDs: number[]): Promise<string> => {
  try {
    const response = await baseAPI(`/document/archive`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/octet-stream' },
      body: JSON.stringify({ ids: documentsIDs }),
    }).then((resp) => {
      return resp.json();
    });
    const modifiedUrl = isDev()
      ? response.link.replace(REACT_APP_S3_URL || '', REACT_APP_S3_PROXY_PATH)
      : response.link;
    return modifiedUrl;
  } catch (e) {
    const error = e as Error;
    throw Error(error.message);
  }
};

export const fetchListOfPredefinedClasses = async (clientID: number, projectID: number): Promise<Array<string>> => {
  try {
    return await baseAPI(`/clients/${clientID}/projects/${projectID}/classifications`, {
      method: 'GET',
    }).then((r) => r.json());
  } catch (e) {
    const error = e as Error;
    throw Error(error.message);
  }
};

export const fetchDocumentReview = async (id: number, signal?: AbortSignal): Promise<Document> =>
  convertResponseToJSON(await baseAPI(`/document/${id}/review`, null, {}, signal));

export const fetchDocumentReviewRandom = async (projectId: number, currentDocId: number | null): Promise<Document> => {
  return convertResponseToJSON(
    await baseAPI(`/document/${projectId}/review/random`, { method: 'GET' }, { currentDocId })
  );
};

export const updateDocument = async (id: number, document: DocumentUpdate): Promise<Document> => {
  return convertResponseToJSON(
    await baseAPI(`/document/${id}`, {
      method: 'PUT',
      body: JSON.stringify(document),
    })
  );
};

export const updateDocumentPatch = async (id: number, document: DocumentUpdate): Promise<Document> => {
  return convertResponseToJSON(
    await baseAPI(`/document/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({ id, ...document }),
    })
  );
};

export const sendModelResultsFeedback = async (
  clientID: number,
  projectID: number,
  documentID: number,
  feedback: ModelResultFeedback
): Promise<Document> => {
  return convertResponseToJSON(
    await baseAPI(`/clients/${clientID}/projects/${projectID}/document/${documentID}/feedback`, {
      method: 'POST',
      body: JSON.stringify(feedback),
    })
  );
};

export const getModelResultsFeedback = async (
  clientID: number,
  projectID: number,
  documentID: number
): Promise<Document> => {
  return convertResponseToJSON(
    await baseAPI(`/clients/${clientID}/projects/${projectID}/document/${documentID}/feedback`, {
      method: 'GET',
    })
  );
};

export const getAllIdsOfDocumentsWithDefiniteStatus = async (projectId: number, documentStatus: DocumentStatus) => {
  const documents = await fetchDocuments({
    projectId,
    statuses: documentStatus,
  });

  return documents.content.map((document) => document.id);
};
