import React from 'react';

export type UseBooleanStateResult = [boolean, () => void, () => void];

/* Usage example:
 *
 * const [isActive, show, hide] = useBooleanState(true); // for modals
 *
 * If you get warning in tests "Warning: An update to %s inside a test was not wrapped in act", check helper skipActWarning(src/test/lib/helpers/skipActWarning.ts)
 */

export const useBooleanState = (initialValue = false): UseBooleanStateResult => {
  const [state, setIsActive] = React.useState(initialValue);
  const setTrue = React.useCallback(() => setIsActive(true), [setIsActive]);
  const setFalse = React.useCallback(() => setIsActive(false), [setIsActive]);

  return [state, setTrue, setFalse];
};
