import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { isAuthenticated, isAdmin } from 'redux/login';
import App from './App';

const mapStateToProps = (state: RootState) => ({
  isAuth: isAuthenticated(state),
  isAdmin: isAdmin(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
