import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const getLoadersState = (state: RootState) => state.loaders;

export const selectLoadersState = createSelector([getLoadersState], (state) => state);

const getLoadingStateByName = (state: RootState, name: string) => state.loaders.requests[name];

export const selectLoadingStateByName = createSelector(
  [getLoadingStateByName],
  (loadingState) => loadingState ?? { status: 'idle' }
);
