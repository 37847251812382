import { DocumentType } from 'generatedSources';

export const getDocumentTypeTitle = (documentType: string) => {
  switch (documentType) {
    case DocumentType['insurance id']:
      return 'Insurance ID';
    case DocumentType['cms1500 claim form']:
      return 'CMS1500 Claim Form';
    case undefined:
      return 'Undefined';
    default: {
      return documentType
        ?.split(' ')
        .map((word) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(' ');
    }
  }
};

export const getDocumentTypeClassname = (documentType: string) => {
  return documentType?.toLowerCase().replace(/ /g, '-');
};

// must be a number
export function validateProjectIdQuery(projectIdQuery: (string | null)[] | null | undefined) {
  if (Array.isArray(projectIdQuery)) {
    return projectIdQuery[0] && !Number.isNaN(+projectIdQuery[0]);
  }
  return projectIdQuery && !Number.isNaN(+projectIdQuery);
}
