import React, { useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T | null = null) => {
  const localStorageValue = localStorage.getItem(key);
  const [itemValue, setItemValue] = useState(localStorageValue);
  const setValue = React.useCallback(
    (newValue: string) => {
      setItemValue(newValue);
      localStorage.setItem(key, newValue);
    },
    [key]
  );
  const removeValue = React.useCallback(() => {
    setItemValue('');
    localStorage.removeItem(key);
  }, [key]);
  const result = React.useMemo(() => {
    const resultValue = itemValue !== null ? itemValue : initialValue;
    return [resultValue, setValue, removeValue];
  }, [initialValue, itemValue, removeValue, setValue]);
  return result;
};
