import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getEntities } from 'redux/document/list';
import * as api from 'lib/api/documents';
import { downloadFile } from 'lib/helpers/files';
import { showSuccessMessage, showErrorMessage, showWarnMessage } from 'components/Common/Alert/Alerts';

export const useFileDownload = () => {
  const entities = useSelector(getEntities);

  return useCallback(
    async (documentsIDs: number[]) => {
      const theOnlyDownloadingDocumentName =
        documentsIDs.length === 1 && entities.find((entity) => entity.id === documentsIDs[0])?.name;

      try {
        showWarnMessage('Downloading will start in a few seconds...');
        const url = await api.fetchMultipleDocumentsUrl(documentsIDs);
        if (url) {
          downloadFile(url);
          const successAlertMessage = theOnlyDownloadingDocumentName
            ? `"${theOnlyDownloadingDocumentName}" file downloaded successfully!`
            : `Files are downloaded successfully!`;
          showSuccessMessage(successAlertMessage);
        }
      } catch (e) {
        const errorAlertMessage = theOnlyDownloadingDocumentName
          ? `Downloading of "${theOnlyDownloadingDocumentName}" file failed.`
          : `Downloading of the files failed.`;
        showErrorMessage(errorAlertMessage);
      }
    },
    [entities]
  );
};
