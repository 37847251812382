import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectClientName } from 'redux/login';
import { selectProjectName } from 'redux/projects';
import * as api from 'lib/api/documents';
import { showErrorMessage } from 'components/Common/Alert/Alerts';

export const useFileUpload = () => {
  const projectName = useSelector(selectProjectName);
  const clientName = useSelector(selectClientName);

  return useCallback(
    (documentName: string) =>
      api.fetchUploadDocumentUrl(documentName, projectName, clientName).catch(() => {
        showErrorMessage('Something went wrong...');
        return '';
      }),
    [clientName, projectName]
  );
};
