import { User, UserRole, UserStatus } from 'generatedSources';

export const getStatusesCounts = (userData: User[]) => {
  return {
    PENDING: userData.filter((el) => el.status === UserStatus.PENDING).length,
    ACTIVE: userData.filter((el) => el.status === UserStatus.ACTIVE).length,
    ARCHIVED: userData.filter((el) => el.status === UserStatus.ARCHIVED).length,
  };
};

export const getRolesCounts = (userData: User[]) => {
  return {
    admin: userData.filter((el) => el.role === UserRole.Admin).length,
    editor: userData.filter((el) => el.role === UserRole.Editor).length,
  };
};
